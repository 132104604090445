<template>
  <div class="wp-cloud-container" >
    <div class="backgroundCollab">
      <div id="switch" style="display: flex; flex-direction: row;" >
        <div ><a-switch v-model:checked="firewallStatus"></a-switch></div>
        <div style=" display: flex; flex-direction: row; flex-wrap: wrap;">
          <span style="margin-left: 5px;">Next-Generation-Firewall {{ firewall2 ? "ausschalten" : "einschalten" }}&nbsp;
            <a-popover title="Hinweis"
              icon="info-circle"
              id="hint">
              <template #content>
                <p style="font-size: 10px; width: 200px;">{{ 'Die Next-Generation-Firewall ist ein mehrstufiges Sicherheitssystem zum Schutz vor modernen Cyberattacken' }}</p>
              </template>
              <ExclamationCircleOutlined />
            </a-popover>
          </span>
        </div>
      </div>
      <div class="cloudContainer">
        <img class="cloud-start" src='../assets/collab-storage.png'/>
          <div :class="['panel add-storage clickable']" key="" @click="addStorage" v-if="isWpServerEmpty" style="align-self: center; display: flex; align-items: center; flex-direction: column;">
            <div class="clickable" >
              <img width="20" src='../assets/add_cross.svg'/>
            </div>
            <div>Collaboration Speicher erstellen</div>
          </div> 
          <div class="panel add-storage " v-if="!isWpServerEmpty" style="align-self: center;">
            <div style="font-weight: 600">{{ wpServer.name }}</div>
            <div style="display: flex; flex-direction: column; padding-left: 10px;">
              <div>
                <div class="text-left clickable" @click="editServer" style=" width: 95px;">
                  <img src='../assets/computer.svg' width="16" style="margin-right: 3px;" />
                  Bearbeiten
                </div>
              </div>
              <div>
                <div class="text-left clickable"  @click="deleteServerModal = true"  style=" width: 75px;">
                  <img src='../assets/trash-2.svg' width="16" style="margin-right: 3px;"/>
                  Löschen
                </div>
              </div> 
            </div>
          </div> 
        </div>
      </div> 
    <a-modal
      :title="'Server löschen?'"
      v-model:open="deleteServerModal"
      :closable="true"
      @cancel="deleteServerModal = false"
      @ok="deleteStorage()"
      okText="Ok"
      cancelText="Abbrechen"
      centered>
      Wollen Sie den Server wirklich löschen?
    </a-modal>
  </div>
</template>

<script setup>

//IMPORTS
import { ref, computed} from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import store from '@/store'

//VARIABLE
const deleteServerModal = ref(false)

//COMPUTED PROPERIES
const serversByType = computed(() => store.getters.serversByType)
const serverById = computed(() => store.getters.serverById)
const send = computed(() => store.getters.send)
const firewall2 = computed(() => store.getters.firewall2)
const getPriceList = computed(() => store.getters.getPriceList)
const selectedServer = computed(() => store.getters.selectedServer)

const wpServer = computed(() => serversByType.value("wpCloud")[0])
const priceList = computed(() => getPriceList.value)
const isWpServerEmpty = computed(() => serversByType.value("wpCloud").length === 0)
const firewallStatus = computed({
  get: () => firewall2.value,
  set: (value) => store.commit("setFirewall2", value),
})

//LIFECYCLE HOOK
onBeforeRouteLeave((to) => {
  if (to.path === '/cloudServer' || to.path === '/iaas' || to.path === '/cybercloudendpoint' || to.path === '/container') {
    if (store.getters.isSidebarOpen) {
      const confirm = window.confirm('Wollen Sie die momentane Konfiguration wirklich abbrechen? Nicht gespeicherte Daten gehen verloren!')
      if (!confirm) {
        return false;
      }
    }
    store.commit("resetServer")
    store.commit("setSidebarVisibility", false);
  }
})

//FUNCTIONS
const setSelectedServer = (server) => store.commit("setSelectedServer", server)
const addServer = () => store.commit("addServer")
const setSidebarVisibility = (visibility) => store.commit("setSidebarVisibility", visibility);
const deleteServer = (id) => store.commit("deleteServer", id)

function addStorage(){
  let server = {
    id: Date.now(),
    type: "wpCloud",
    name: "Collaboration & Cloud Speicher",
    configuration: {},
  }

  server.configuration = {
    "HDD": 0,
    "SSD": 100,
    "MEMORY": 4,
    "vCPU": 2,
  }

  setSelectedServer(server)
  addServer()
  setSidebarVisibility(true)
}

function editServer () {
  setSelectedServer(wpServer.value)
  setSidebarVisibility(true)
}

function deleteStorage () {
  if (wpServer.value) {
    deleteServer(wpServer.value.id)
  }
  setSidebarVisibility(false)
  deleteServerModal.value = false
}

</script>


<style lang="scss" scoped>
.cloudContainer {
  display: flex;
  flex-direction: row;
}
.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}
.text-left {
  display: flex;
  font-size: 16px;
  align-items: center;
  margin-top: 3px;
}
.backgroundCollab {
  border-radius: 10px;
  background-color: rgba(181,181,181,0.11);
  padding: 10px;
}
.panel {
  //background: url("../assets/panel.svg") no-repeat;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.add-storage {
  width: 200px;
}

.desktop {
  display: none;
}
.cloud-start {
  width: 700px;
}


@media (min-width: 1200px) {
  .panel {
    width: 200px;
  }
  .add-storage {
    width: 200px;
    height: 115px;
  }
  .desktop {
    display: inherit;
  }
  .mobil {
    display: none;
  }
  .cloud-start {
    width:500px;
    display: flex;
    justify-content: left;
  }
}
@media (max-width: 1200px) {
  .panel {
    width: 200px;
    padding: 5px;
  }
  .cloud-start {
    width:400px;
  }
}

@media (max-width: 930px) {
  .cloud-start {
    width:400px;
  }
  .cloudContainer {
    flex-direction: column;
    align-items: center;
  }
  .panel{
    height: 120px;
  }
}

@media (max-width: 790px) {
}
</style>
<template>
    <a-modal
      v-if="openModal"
      style="top: 20px;"
      centered
      :title="resourceToDelete + ' ' + resourceName + ' entfernen'"
      :closable="false"
      :loading="loading"
      :maskClosable="false"
      v-model:open="openModal"
    >
      <div style="display: flex; justify-content: center;">
        <DeleteOutlined :style="{fontSize: '40px', color: 'red'}" />
        <span style="margin-left: 20px;"><span> {{ modalText() }}</span><span style="font-weight: bold;">{{ resourceName }}</span><span>{{ ' wirklich löschen möchten?' }}</span></span>
      </div>
      <template #footer style="text-align: right;">
        <a-button id="deleteUserAccountCancel" type="default" @click="closeDeleteModal()">Abbrechen</a-button>
        <a-button id="deleteUserAccountOk" type="primary" :loading="loading" @click="deleteResource()">Löschen!</a-button>
      </template>
    </a-modal>
</template>

<script setup>

//IMPORTS
import { DeleteOutlined } from '@ant-design/icons-vue'
import api from '../../apis/apis'
import { notification } from 'ant-design-vue'
import { ref, watch, computed } from 'vue'

//VARIABLES
const loading   = ref(false)
const openModal = ref(false)

//PROPS
const props = defineProps ({
    resourceToDelete: {
      type: String,
      required: true
    },
    deletionModal: {
      type: Boolean,
      required: true
    },
    resourceName: {
      type: String,
      required: true
    },
    resourceUuid: {
      type: String,
      required: true
    },
    userDomainId: {
      type: String,
      required: false
    },
    accountName: {
      type: String,
      required: false
    },
    configuratorId: {
      type: String,
      required: false
    }
})

//WATCH
const deletionModal = computed(() => props.deletionModal)
watch (deletionModal, function(newVal) {
        openModal.value = newVal
})

//EMITS
const emit = defineEmits(['reload-accounts', 'close-modal', 'loadConfigs']);

//FUNCTIONS
async function deleteResource () {
  loading.value = true
  switch (props.resourceToDelete) {
    case 'Benutzerkonto':
     await deleteAccount(props.resourceUuid)
        break
    case 'Angebot':
      await deleteConfigurator(props.configuratorId)
        break
    case 'Benutzer':
      await deleteUser(props.resourceUuid)
        break
    default:
      console.log("Es ist keine Ressource zum löschen vorhanden")
  }
}

async function deleteAccount (uuid) {
  await api.deleteAccount(uuid).then(response =>{
    emit('reload-accounts')
    emit('close-modal')
    loading.value = false
    notification.success({
      message: 'Erfolg',
      description:
            'Das Benutzerkonto wurde erfolgreich gelöscht.',
      duration: 4
    })
  }).catch(error => {
    notification.error({
      message: 'Fehler beim Löschen des Benutzerkontos',
      description:
        'Das Benutzerkonto konnte nicht gelöscht werden, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
      duration: 0
  })
  emit('close-modal')
  loading.value = false
  console.log("error deleting Account id: " ,error)
  })
}

async function deleteUser (uuid) {
  loading.value = true
  await api.deleteUser(uuid).then(response =>{
    notification.success({
    message: 'Erfolg',
    description:
        'Das Benutzerkonto wurde erfolgreich gelöscht.',
    duration: 4
  })
    loading.value = false
    emit('reload-user', props.accountName, props.userDomainId)
    emit('close-modal')
  }).catch(error => {
    notification.error({
    message: 'Fehler beim Löschen des Benutzers',
    description:
          'Der Benutzer konnte nicht gelöscht werden, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
    duration: 0
  })
  loading.value = false
  emit('close-modal')
  console.log("error deleting User: " ,error)
  })
}

async function deleteConfigurator (id) {
  loading.value = true
  var params = new FormData()
  params.append('id', id)
  await api.deleteConfigurator(params).then(response =>{
    notification.success({
      message: 'Erfolg',
      description:
          'Das Angebot wurde gelöscht.',
      duration: 6
  })
  loading.value = false
  emit('loadConfigs', 2)
  emit('close-modal')
  }).catch(error => {
    notification.error({
    message: 'Fehler beim Löschen des Angebotes',
    description:
      'Das Angebot konnte nicht gelöscht werden, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
    duration: 0
  })
    loading.value = false
    emit('close-modal')
    console.log("error deleting User: " ,error)
  })
}

function closeDeleteModal () {
  openModal.value = false
  loading.value = false
  emit('close-modal')
}

function modalText () {
  var text
  switch(props.resourceToDelete) {
    case 'Benutzerkonto':
      text = 'Sind Sie sich sicher, dass Sie das Benutzerkonto '
      break;
    case 'Benutzer':
      text = 'Sind Sie sich sicher, dass Sie den Benutzer '
      break;
    case 'Angebot':
      text = 'Sind Sie sich sicher, dass Sie das Angebot '
      break;
    default:
      console.log('Ressource ist nicht vorhanden')
    }
    return text
  }

</script>
<template>
  <div class="kdz-main-view">
    <a-col class="mainContent">
      <div class="textPosition">
        Wählen Sie Ihre gewünschte Cloudvariante!
      </div>
      
      <img src="../assets/puzzle_cloud.png" class="cloudImage" >
    </a-col>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.kdz-main-view {
  font-size: 30px;
  height: 540px;
  width: 1220px;
  margin-left: 4%;
}
.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 15%;
}

.cloudImage {
  width: 500px;
}

.textPosition {
  position: relative;
  //left: -160px;
  font-weight: bold;
  font-size: 18px;
}

@media (max-width: 1200px) {
  .kdz-main-view {
    width: 600px;
  }
  .textPosition {
    position: relative;
    left: -60px;
  }
  .cloudImage {
    width: 400px;
  }
}

@media (max-width: 930px) {
  .kdz-main-view {
    width: 400px;
  }
  .textPosition {
    position: relative;
    left: -30px;
  }
  .cloudImage {
    width: 350px;
  }
}

@media (max-width: 790px) {
  .kdz-main-view {
    width: 460px;
    height: auto;
    max-width: 100%;
  }
  .textPosition {
    position: relative;
  }
  .mainContent {
    align-items: center;
  }
  .cloudImage {
    width: 350px;
  }
}

</style>

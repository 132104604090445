<template>
    <a-modal
      v-if="openModal"
      style="height: 50em;"
      v-model:open="openModal"
      :title="'Benutzer bearbeiten'"
      :closable="false"
      :maskClosable="false"
      :destroyOnClose="true"
      :loading="loading"
      cancelText="Abbrechen"
      @cancel="closeModal"
      @ok="handleSubmit"
      centered>
        <a-form
          :model="form"
          :rules="rules"
          :loading="loading"
          layout="vertical"
          @finish="handleSubmit">
          <!-- WP Implementation -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="username" name="username">
                <template #label>
                  <TooltipLabel :title="'Name des Benutzers'" :tooltip="'Name des eigentlichen Benutzer, welches vom System benutzt wird. Keine Sonderzeichen erlaubt um Requestfehler zu verhindern.'"/>
                </template>
                <a-input v-model:value="form.username" :placeholder="'Name des Benutzerkontos'" />
              </a-form-item>
            </a-col>
            <!-- Email -->
            <a-col :md="24" :lg="12">
              <a-form-item ref="email" name="email">
                <template #label>
                  <tooltip-label :title="'E-Mail'" :tooltip="'Bitte geben Sie die E-Mail des Nutzers an.\nEs muss eine gültige Email sein.\nErlaubte Zeichen: \nBuchstaben, Zahlen, -_@.'"/>
                </template>
                <a-input
                  v-model:value="form.email"
                  :placeholder="'E-Mail'" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="firstname" name="firstname">
                <template #label>
                  <tooltip-label :title="'Vorname'" :tooltip="'Bitte geben Sie den Vornamen des Nutzers an.'"/>
                </template>
                <a-input
                  v-model:value="form.firstname"
                  :placeholder="'Vorname'" />
              </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
              <a-form-item ref="lastname" name="lastname">
                <template #label>
                  <tooltip-label :title="'Nachname'" :tooltip="'Bitte geben Sie den Nachnamen des Nutzers an.'"/>
                </template>
                <a-input
                  v-model:value="form.lastname"
                  :placeholder="'Nachname'" />
              </a-form-item>
            </a-col>
          </a-row>
      </a-form>
    </a-modal>
</template>

<script setup>

//IMPORTS
import { ref, reactive, toRaw, watch, onMounted } from 'vue';
import api from '@/apis/apis';
import TooltipLabel from '@/components/TooltipLabel.vue';
import { notification } from 'ant-design-vue';

//VARIABLES
const openModal = ref(false)
const loading = ref(false)

const emailRegex = /^[^@]+@[^@]{2,}\.[^@]{2,}$/
const specialCharsMail = /[-_@.]/
const specialChars = /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/

const form = reactive({
  username: undefined,
  email: undefined,
  firstname: undefined,
  lastname: undefined,
})

const rules = reactive({
  username: [
    { required: true, message: 'Bitte geben Sie einen Benutzernamen an.' },
    { validator: validateAccountName },
  ],
  email: [
    { required: true, message: '' },
    { validator: validateEmail },
  ],
  firstname: [{ required: true, message: 'Bitte geben Sie den Vornamen des Nutzers an.' }],
  lastname: [{ required: true, message: 'Bitte geben Sie den Nachnamen des Nutzers an.' }],
});


//Props
const props = defineProps({
  userResource: Object,
  editUserModal: Boolean,
});

//WATCHER
watch(() => props.editUserModal, (newVal) => {
  openModal.value = newVal;
});

watch(() => props.userResource, (newVal) => {
  if (newVal) {
    form.username = newVal.username;
    form.email = newVal.email;
    form.firstname = newVal.firstname;
    form.lastname = newVal.lastname;
  }
}, { deep: true })

//EMITS
const emit = defineEmits(['reload-user', 'close-modal'])


//FUNCTIONS
function validateAccountName(rule, value) {
  let errorMessage = 'Der Benutzername erfüllt nicht alle Anforderungen!';
  let validated = true;

  if (!specialChars.test(value)) {
    validated = false;
    errorMessage += "\nSonderzeichen sind nicht erlaubt!";
  }

  if (value === undefined || value.length < 4) {
    validated = false;
    errorMessage += "\nDer Benutzername muss mindestens 4 Zeichen lang sein!";
  }

  return validated ? Promise.resolve() : Promise.reject(errorMessage);
}

function validateEmail(rule, value) {
  const errorMessage = 'Bitte geben Sie eine gültige E-Mail an. \nBeispiel: Beispiel@Email1.com\nNur Zahlen, Buchstaben und -_@. sind erlaubt!';

  if (emailRegex.test(value) && specialCharsMail.test(value)) {
    return Promise.resolve()
  }

  return Promise.reject(errorMessage)
}

async function handleSubmit(e) {
  e.preventDefault()

  if (loading.value) return;

  try {

    const values = toRaw(form)

    if (
      values.username === props.userResource.username &&
      values.email === props.userResource.email &&
      values.firstname === props.userResource.firstname &&
      values.lastname === props.userResource.lastname
    ) {

      closeModal()
      return
    }

    loading.value = true;

    const params = new FormData();
    params.append('useruuid', props.userResource.userUuid);
    params.append('username', values.username);
    params.append('email', values.email);
    params.append('firstname', values.firstname);
    params.append('lastname', values.lastname);

    await api.updateUser(params);

    notification.success({
      message: 'Erfolg',
      description: 'Der Benutzer wurde erfolgreich aktualisiert.',
      duration: 6,
    })

    closeModal()

  } catch (error) {
    console.log(error)

    notification.error({
      message: 'Fehler beim Aktualisieren eines Benutzers.',
      description:
        `Beim Aktualisieren eines Benutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. (${error.response.data.split('):')[1]})`,
      duration: 0,
    })

   loading.value = false
   closeModal() 
}
}

function closeModal() {

   form.username = props.userResource.username
   form.email = props.userResource.email
   form.firstname = props.userResource.firstname
   form.lastname = props.userResource.lastname
   openModal.value = false;
   loading.value = false
   emit('reload-user', props.userResource.account, props.userResource.domainid)
   emit('close-modal'); 
}
</script>

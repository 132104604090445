<template>
    <a-spin :spinning="loading">
        <a-modal
            style="width: 400px;"
            v-model:open="openModal"
            :title="$route.path === '/customers'? 'Angebot duplizieren' :'Angebot speichern'"
            :closable="false"
            :maskClosable="false"
            cancelText="Abbrechen"
            @cancel="closeModal"
            @ok="handleSaveConfigSubmit"
            destroyOnClose
            :confirmLoading="loading"
            centered>
            <a-form
                ref="formRefSave"
                :model="formSave"
                :rules="rulesSave"
                :loading="loading"
                layout="vertical"
                @finish="handleSaveConfigSubmit">
                <!-- WP Implementation -->
                <a-form-item ref="configName" name="configName">
                    <template #label>
                        <tooltip-label :title="'Angebotsname'" :tooltip="'Geben Sie der abzuspeichernden Konfiguration einen eindeutigen Namen, dieser wird im Angebot angezeigt. Die Maximallänge betragt 60 Zeichen, erlaubt sind nur Buchstaben, Zahlen, ._ und Leerzeichen!'"/>
                    </template>
                    <a-input
                        placeholder="Name des Angebots"
                        id="saveConfigNameInput"
                        v-model:value="formSave.configName"
                    >
                    </a-input>
                </a-form-item>
                <a-form-item ref="configNumber" name="configNumber">
                    <template #label>
                        <span>Angebotsnummer</span>
                    </template>
                    <a-input
                        placeholder="Angebotsnummer"
                        id="saveConfigNumberInput"
                        v-model:value="formSave.configNumber"
                        disabled
                    >
                    </a-input>
                </a-form-item>
                <a-form-item ref="selectedSubAccountForSave" name="selectedSubAccountForSave">
                    <template #label>
                        <tooltip-label :title="'Customer auswählen'" :tooltip="'Bitte wählen Sie den Customer aus, für den Sie das konfigurierte Angebot speichern möchten'"/>
                    </template>
                    <a-select
                        id="subAccountSelect"
                        @change="handleSelectChange"
                        placeholder="Customer auswählen"
                        v-model:value="formSave.selectedSubAccountForSave"
                    >
                        <a-select-option v-for="account in allAccounts" :key="account.id" >
                        {{ account.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
      </a-modal>
    </a-spin>
</template>

<script setup>

//IMPORTS
import { notification } from 'ant-design-vue'
import api from '@/apis/apis'
import { ref, reactive, toRaw, computed, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import TooltipLabel from '@/components/TooltipLabel.vue'
import store from '@/store'

//VARIABLES
const configNameRegex   = /^[\u00C0-\u017Fa-zA-Z0-9_. ]*$/
const loading           = ref(false)
const openModal         = ref(false)

const setImportedConfigName = (value) => store.commit('setImportedConfigName', value)
const setImportedConfigNumber = (value) => store.commit('setImportedConfigNumber', value)
const setCustomerId = (value) => store.commit('setCustomerId', value)

const formRefSave = ref(null)
const formSave = reactive({})
const rulesSave = reactive({
  configName: [
    { required: true, message: "Bitte geben sie einen Konfigurationsnamen an." },
    { validator: validateConfigName } // Stelle sicher, dass validateConfigName definiert ist
  ],
  selectedSubAccountForSave: [
    { required: true, message: "Für welchen Account möchten sie das Angebot speichern?" }
  ]
})

const route = useRoute()

//PROPS
const props = defineProps({
    showSaveConfig: {
        type: Boolean,
        default: false
    },
    accountId: {
        type: String,
         default: ""
    },
    importObjectString: {
        type: String,
        default: ""
    },
    billObject: {
        type: Object,
        default: []
    },
    allAccounts : {
        type: Object,
        default: []
    },
})

//WATCH
const showSaveConfigConst = computed(() => props.showSaveConfig)
watch(showSaveConfigConst, (newVal) => {
    if ( newVal === true) {
        if(route.path !== '/customers') {
            formSave.selectedSubAccountForSave  = store.getters.getCustomerId
            formSave.configName                 = store.getters.getImportedConfigName
            formSave.configNumber               = store.getters.getImportedConfigNumber
        }
    }
    openModal.value = newVal
})

//FORM
const initForm = () => {}

//EMITS
const emit = defineEmits(['loadConfigs', 'close-modal']);

// LIFECYCLE HOOKS
onMounted(() => {
  initForm();
});

//FUNCTIONS
function handleSaveConfigSubmit (e) {
    e.preventDefault()
    if (loading.value) return
    const values = toRaw(formSave)
    if (store.getters.getConfigImportId && route.path !== '/customers') {
        formRefSave.value.validate().then(async () => {
            updateConfig()
            setCustomerId(values.selectedSubAccountForSave)
            setImportedConfigName(values.configName)
        }).catch(error => {
            console.log(error)
            loading.value = false
        })
    } else {
        formRefSave.value.validate().then(async () => {
        setCustomerId(values.selectedSubAccountForSave)
        setImportedConfigName(values.configName)
        var params = new FormData()
        params.append('object', JSON.stringify(props.billObject))
        params.append('importObject', props.importObjectString)
        params.append('resellerUuid', props.accountId)
        params.append('customerUuid', values.selectedSubAccountForSave)
        params.append('configName', values.configName)
        params.append('partnerStatus', store.getters.discountType)
        await api.saveConfigurator(params).then(response =>{
            if(route.path !== '/customers') {
                store.commit('setConfigImportId', response.data.configId)
            }  
            setImportedConfigNumber(response.data.configNumber)
            loading.value = false
            notification.success({
                message: 'Erfolg!',
                description: 'Die Konfiguration "' + values.configName + '" wurde erfolgreich gespeichert!',
                duration: 5
            })
            store.commit("setWorkingOnOffer", false)
            closeModal()
            if (route.path === '/customers') {
                emit('loadConfigs',2)
            } 
        }).catch(error => {
            notification.error({
            message: 'Es ist ein Fehler aufgetreten!',
            description: 'Die Konfiguration "' + values.configName + '" konnte nicht erfolgreich gespeichert werden. Bitte versuchen Sie es erneut!',
            duration: 0
            })
            console.log("Error saving config",error)
            loading.value = false
            closeModal()
            if (route.path === '/customers') {
                emit('loadConfigs',2)
            } 
        })
        }).catch(error => {
            console.log(error)
            loading.value = false
        })
    }
}

async function updateConfig () {
    const values = toRaw(formSave)
    var updateParams = new FormData()
    updateParams.append('record', store.getters.getConfigImportId)
    updateParams.append('object', JSON.stringify(store.getters.bill))
    updateParams.append('importObject', JSON.stringify(store.getters.serverList))
    updateParams.append('customerUuid', values.selectedSubAccountForSave)
    updateParams.append('configName', values.configName)
    updateParams.append('partnerStatus', store.getters.discountType)
    await api.updateConfigurator(updateParams).then(response =>{
        loading.value = false
        notification.success({
            message: 'Erfolg!',
            description: 'Die Konfiguration wurde erfolgreich bearbeitet!',
            duration: 5
        })
        store.commit("setWorkingOnOffer", false)
        closeModal()
        if (route.path === '/customers') {
            emit('loadConfigs',2)
        } 
    }).catch(error => {
        notification.error({
        message: 'Es ist ein Fehler aufgetreten!',
        description: 'Die Konfiguration konnte nicht erfolgreich gespeichert werden. Bitte versuchen Sie es erneut!',
        duration: 0
        })
        console.log("Error saving config",error)
        loading.value = false
        closeModal()
        if (route.path === '/customers') {
            emit('loadConfigs',2)
        } 
    })
}

function handleSelectChange(value) {
    var subAccount = props.allAccounts.filter(account => account.id === value)[0]
    if (subAccount !== undefined) {
        store.commit("setCustomerName", subAccount.name)
    }
}

function closeModal () {
    emit('close-modal')
    if(route.path === "/customers"){
        formSave.configName = undefined
        formSave.configNumber = undefined
        formSave.selectedSubAccountForSave = undefined
    }
}

function validateConfigName (rule, value) {
    const errorMessage = 'Eine gültiger Konfigurationsname besteht aus Buchstaben, Zahlen ._ und hat maximal 60 Zeichen.'
    if(value === undefined || value.length <= 60) {
        if (configNameRegex.test(value)) {
            return Promise.resolve()
        }
    }
    return Promise.reject(errorMessage)
}

</script>
<template>
  <div
    :class="[
      'kdz-sidebar',
      { open: isSidebarOpen },
      // (discountType === 'Holz' ? 'woodPartner' : (discountType === 'Bronze' ? 'bronzePartner' : (discountType === 'Silber' ? 'silverPartner' : (discountType === 'Gold' ? 'goldPartner' : (discountType === 'Platin' ? 'platinumPartner' : 'standardPartner'))))),
    ]"
  >
    <div class="configFormularPosition"><ConfigFormular /></div>
  </div>
</template>

<script setup >

//IMPORTS
import ConfigFormular from "@/components/forms/ConfigFormular.vue"
import { computed } from 'vue'
import store from '@/store'

//COMPUTED PROPERTIES
const isSidebarOpen = computed(() => store.getters.isSidebarOpen)
const discountType = computed(() => store.getters.discountType)

</script>

<style scoped>
.kdz-sidebar {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 0;
  bottom: 0;
  transition: 0.8s all ease-in-out;
  border-right: 1px solid #ececec;
  color: #fff;
  z-index: 20;
  font-size: 15px;
  background: #5B5A5A;
}
.configFormularPosition {
  display: flex; 
  position: relative;
  top: 20px;
  padding: 0 10px;
  //top: 100px;
}

.open {
  left: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .kdz-sidebar {
    top: 100px;
    bottom: 50px;
  }
  .open {
    left: 0;
    width: 320px;
  }
}

@media (max-width: 767px) {
  .kdz-sidebar {
    top: 100px;
    bottom: 50px;
    padding-top: 20px;
  }
  .open {
    left: 0;
    width: 90vw;
    max-width: 320px;
  }
  .configFormularPosition {
    display: flex; 
    position: relative; 
    top: 5px;
  }
}
</style>

<template>
  <div class="backup-container kdz-height">
    <div class="backup-img-container">
    <img class="kdz-config-img pipe-long" src='../assets/pipe_long.svg'/> 
     <img class="kdz-config-img pipe" src='../assets/pipe.svg'/>
      <img class="kdz-config-img server-farm" src='../assets/localserver.svg'/>
      <img class="kdz-config-img cloud" src='../assets/backup_cloud.svg'/>
    </div>
    <div class="image-row">
      <div style="display: flex; flex-direction: column;">
      <div @click="createBackup" v-if="!isEmpty && !isSidebarOpen">
        <div class="text-left clickable"  style="width: 230px; margin-top: 3px;font-size: 16px;">
          <img src='../assets/computer.svg' width="20" style="margin-right: 8px;" />
          Neuen Endpoint erstellen
        </div>
      </div>
      </div>
    </div>

    <div class="backups">
      <div class="backupContainer">
          <div v-for="(backup, i) in backups" :key="i" style="display: flex; width: 200px; margin-top: 5px;">
            <BackUpServer :server="backup" />
          </div>
        <img src='../assets/wp-logo.svg' class="kdz-config-img logo"/>
      </div>
      <div class="panel clickable" key="" @click="createBackup" v-if="isEmpty">
        <div>
          <img width="20" src='../assets/add_cross.svg'/>
        </div>
        <div>
          <div >Neuen Endpoint erstellen</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

//IMPORTS
import { computed, onMounted, onBeforeUnmount} from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import BackUpServer from "@/components/server/BackUpServer.vue"
import store from '@/store'

//COMPUTED PROPERTIES
const serversByType = computed(() => store.getters.serversByType)
//const send = computed(() => store.getters.send)
const isSidebarOpen = computed(() => store.getters.isSidebarOpen)

const backups = computed(() => serversByType.value("cybercloudendpoint"))
const isEmpty = computed(() => backups.value.length === 0)

//LIFECYCLE HOOKS
onMounted(() => {
  window.onresize = () => {
    const screenWidth = window.screen.width
  }
})

onBeforeRouteLeave((to, from) => {
  if (to.path === '/cloudServer' || to.path === '/iaas' || to.path === '/collaboration' || to.path === '/container') {
    if (store.getters.isSidebarOpen) {
      const confirm = window.confirm('Wollen Sie die momentane Konfiguration wirklich abbrechen? Nicht gespeicherte Daten gehen verloren!');
      if (!confirm) {
        return false;
      }
    }

    resetServer();
    setSidebarVisibility(false);
  }
})

onBeforeUnmount(() => {
  window.onresize = null;
})

//FUNCTIONS
function setSelectedServer(server) {
  store.commit("setSelectedServer", server);
}

function setSidebarVisibility(value) {
  store.commit("setSidebarVisibility", value);
}

function resetServer() {
  store.commit("resetServer");
}

function addServer(server) {
  store.commit("addServer", server);
}

function createBackup() {
  let server = {
    id: Date.now(),
    type: "cybercloudendpoint",
    name: "Cyber Cloud Endpoint",
    endpointType: "Einzeln",
    configuration: {
      'Cyber_Cloud_Endpoint': 0,
      'AcronisRBS': 0,
    },
  }

  setSelectedServer(server)
  addServer(server)
  setSidebarVisibility(true)
}

</script>

<style lang="scss" scoped>

.backupContainer {
  width: 460px;
  display: flex; 
  flex-wrap: wrap;
  margin-left: 165px;
  justify-content: space-around;
}
.text-left {
  display: flex;
  align-items: left;
  font-size: 16px;
  align-items: center;
}
.kdz-config-img {
  position: absolute;
}
.panel {
  //background: url("../assets/panel.svg") no-repeat;
  width: 180px;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
.backup-container {
  position: relative;
}

.image-row {
  height: 200px;
  display: flex;
  justify-content: right;
}

.backups {
  //background-color: rgba(181,181,181,0.11);
  width: 100%;
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.logo {
display: block;
width: 200px;
}


.pipe-long {
top: 120px;
width: 200px;
left: 10%;
display: none;
}

.add-cross {
bottom: 30%;
left: 40%;
z-index: 4;
display: none
}
.clickable {
cursor: pointer;

&:hover {
color: #d02c2a;
}
}

.cloud {
z-index: 2;
top: 70px;
left: 160px;
width: 160px;
// padding: 10px 20px;
border-radius: 20px;
}

.image-row {
margin-bottom: 40px;
height: 150px;
}


.server-farm {
width: 150px;
top: 5%;
left: 0;
}


@media (min-width: 1200px) {
.panel {
//background: url("../assets/panel.svg") no-repeat;
width: 180px;
height: 100px;
display: flex;
justify-content: center;
flex-direction: column;
margin-top: 70px;
margin-left: 200px;
}
.server-farm {
width: 250px;
z-index: 2;
left: -40px;
}
.logo {
top: 235px;
right: 40px;
}

.mobil {
display: none;
}

.backups {
min-height: 270px;
height: auto;
width: auto;
margin-left: 230px;
}

.add-cross {
display: block;
}

.pipe {
width: 280px;
top: 200px;
left: 20px;
height: auto;
transform: none;
z-index: 1;
}

.cloud {
top: 240px;
left: 150px;
width: 250px;
padding: 0;
}
}
@media (max-width: 1199px) {
.backupContainer {
margin-left: 100px;
width: 180px;
}
.panel {
margin-left: 110px;
z-index: 5;
position: relative;
}
.server-farm {
width: 200px;
z-index: 2;
left: -40px;
}
.logo {
top: 258px;
}

.mobil {
display: none;
}

.backups {
min-height: 270px;
height: auto;
width: 360px;
margin-left: 230px;
}

.add-cross {
display: block;
}

.pipe {
width: 226px;
top: 200px;
left: 20px;
height: auto;
transform: none;
z-index: 1;
}

.cloud {
top: 240px;
left: 135px;
width: 200px;
padding: 0;
}
}

@media (max-width: 930px) {
.panel {
margin-left: 135px;
}
.logo{
top: 300px;
}
.server-farm {
top: 0;
left: -20px;
}
.pipe-long {
top: 140px;
width: 300px;
left: 30px;
display: flex;
}

.pipe {
display: none;
}
.cloud {
top: 90px;
left: 240px;
width: 200px;
padding: 0;
}
.backups {
//background-color: rgba(181,181,181,0.11);
width: 100%;
margin-left: 0;
margin-top: 100px;
}
.backupContainer{
width: 100%;
margin-left: 0;

}
}

@media (max-width: 490px) {

.server-farm {
width: 160px;
top: 10%;
left: -10px;
}
.cloud {
top: 110px;
left: 200px;
width: 170px;
padding: 0;
}
.image-row {
margin-bottom: 40px;
height: 150px;
justify-content: center;
}
}
</style>
<template>
  <div class="dashboardContent flexCenter" style="width: 100%; align-items: center;">
    <div class="placeholder"></div>
    <div class="umsatzContent">
      <div>
        <img v-if="partnerPlan === 'Holz'" src="../assets/holz.png" width="220" height="130" style="position: relative;">
        <img v-if="partnerPlan === 'Bronze'" src="../assets/bronze.png" width="220" height="130" style="position: relative;">
        <img v-if="partnerPlan === 'Silber'" src="../assets/silber.png" width="220" height="130" style="position: relative;">
        <img v-if="partnerPlan === 'Gold'" src="../assets/gold.png" width="220" height="130" style="position: relative;">
        <img v-if="partnerPlan === 'Platin'" src="../assets/platin.png" width="220" height="130" style="position: relative;">
      </div>
      <div class="flexCenter">
        <div style=" border-bottom: 3px solid #D02C2A; height: 60px; width: 200px; margin-bottom: 5px;">
        <h1>Umsatz</h1>
        </div>
      </div>
      <div class="flexCenter">
        <div class="flexCenter" style="align-items: center; background-color: rgba(181,181,181,0.2); width: 300px; height: 300px; border-radius: 10px;">
        <a-spin tip="Lade Umsätze..." :spinning="salesLoading">
        <div class="flexCenter" style="flex-direction: column; width: 280px;" v-if="salesVolume.length !== 0">
          <div v-for="salesData in salesVolume" :key="salesData.Month" style="margin-top: 20px;">
            <strong>{{ salesData.Month }}</strong>
            <div class="flexSpaceBetween" style="font-size: 24px;">
              <div style="width: 49%">
              {{  salesData.TOTAL.toFixed(2) + ' €' }}</div>
              <div style="border: 1px solid rgb(168, 168, 168);"></div>
              <div style="width: 49%">
                {{ salesData.TotalNoDiscount.toFixed(2) + ' €' }}
              </div>
            </div>
          </div>
          <div class="flexSpaceBetween" style="font-size: 20px; color: rgb(168, 168, 168); margin-top: 15px;">
            <strong style="width: 49%">
            {{  'rabattiert' }}</strong>
            <strong style="width: 49%">
              {{ 'unrabattiert' }}
            </strong>
          </div>
        </div>
        </a-spin>
        </div>
      </div>
    </div>
    <div class="discount flexCenter">
        <div style="margin-right: 5px; display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 20%;">
          <a-card size="small" title="Rabattstufen" style="width: 350px;">
            <a-card-grid style="display: flex; width: 48%; height: 70px;">
              <div class="discountImage">
                <img src="../assets/holz.png" width="80" height="50">
                20% Rabatt
              </div>
            </a-card-grid>
            <a-card-grid style="width: 52%; text-align: center">100€ min. Umsatz</a-card-grid>
            <a-card-grid style="display: flex; width: 48%; height: 70px;">
              <div class="discountImage">
                <img src="../assets/bronze.png" width="80" height="50">
                30% Rabatt
              </div>
            </a-card-grid>
            <a-card-grid style="width: 52%; text-align: center">1000€ min. Umsatz</a-card-grid>
            <a-card-grid style="display: flex; width: 48%; height: 70px;">
              <div class="discountImage">
                <img src="../assets/silber.png" width="80" height="50">
                35% Rabatt
              </div>
            </a-card-grid>
            <a-card-grid style="width: 52%; text-align: center">3000€ min. Umsatz</a-card-grid>
            <a-card-grid style="display: flex; width: 48%; height: 70px;">
              <div class="discountImage">
                <img src="../assets/gold.png" width="80" height="50">
                40% Rabatt
              </div>
            </a-card-grid>
            <a-card-grid style="width: 52%; text-align: center">7000€ min. Umsatz</a-card-grid>
            <a-card-grid style="display: flex; width: 48%; height: 70px;">
              <div class="discountImage">
                <img src="../assets/platin.png" width="80" height="50">
                47% Rabatt
              </div>
            </a-card-grid>
            <a-card-grid style="width: 52%; text-align: center">15000€ min. Umsatz</a-card-grid>
          </a-card>
          <div style="margin-top: 10px;">*Die Rabattstufen gelten nur für CPU, RAM, </div>
          <div>Storage, IP, NGFW, TSPlus und GPU</div>
        </div>
      </div> 
    </div>
</template>

<script setup>

  //IMPORTS
  import { ref, onMounted } from 'vue'
  import api from '@/apis/apis'
  import store from '@/store'

  //VARIABLES
  var partnerPlan = ''
  var accountid   = ''
  const salesLoading  = ref(false)
  const salesVolume   = ref([])
  const monthsGerman  = new Map([["January","Januar"], ["February","Februar"], ["March","März"],
   ["April","April"], ["May","Mai"], ["June","Juni"], ["July","Juli"], ["August","August"], 
   ["September","September"], ["October","Oktober"],["November","November"], ["December","Dezember"],])
  const currentYear   = new Date().getFullYear()

  //LIFECYCLE HOOKS
  onMounted( () => {
    fetchData()
  })

  //FUNCTIONS
  function getCookie(cname){
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function fetchData() {
    accountid = getCookie('account')
    if (getCookie('authenticated') === 'true'){
      getSalesVolume()
      getPartnerPlan()
    }
  }

  function getPartnerPlan () {
    api.getPartnerWithPlan(accountid).then(response => {
      partnerPlan = response.data.plan.name
      store.commit("setDiscountType", partnerPlan)
      store.commit("setOriginalDiscountType", partnerPlan)
      store.commit("setPartnerDiscount", response.data.plan.discount)
    }).catch(error => {
      console.log(error)
    })
  }

  async function getSalesVolume () {
    salesLoading.value = true
    let params = new FormData()
    params.append('account_uuid', accountid)
    params.append('year', currentYear)
    await api.getSalesVolume(params).then(response => {
      salesVolume.value = Object.values(response.data)
      for (var salesData of salesVolume.value) {
        salesData.Month = monthsGerman.get(salesData.Month)
      }
      salesVolume.value.reverse()
      salesLoading.value = false
    }).catch(error => {
      console.log(error)
      salesLoading.value = false
    })
  }

</script>

<style scoped>
  .flexCenter {
    display: flex;
    justify-content: center;
  }
  .flexSpaceBetween {
    display: flex;
    justify-content: space-around;
  }
  .umsatzContent {
    display: flex;
    width: 50%;
    flex-direction: column;
    margin-top: 40px;
  }
  .discount {
    width: 20%;
  }
  .placeholder {
    width: 20%;
  }
  .discountImage {
    display: flex; 
    align-items: center; 
    position: relative;
  }

  @media only screen and (max-width: 1150px) {
  .placeholder {
    display: none
  }
  .umsatzContent {
    width: 50%;
    margin-bottom: 40px;
  }
  .dashboardContent {
    flex-direction: column;
  }
  .discountImage {
    display: flex; 
    align-items: center; 
    position: relative;
  }
}
</style>

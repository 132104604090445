export const dialog = {
    methods: {
        confirmDialog(title, message, callback) {
            this.$bvModal.msgBoxConfirm(message, {
                title: title,
                size: "md",
                okVariant: "danger",
                okTitle: "Ja",
                cancelTitle: "Nein",
                cancelVariant: "light",
                hideHeaderClose: true,
                buttonSize: "lg",
                centered: true,
            }).then(callback);
        }
    }
};

export const os = {
    data() {
        return {
            osNames: {
                windows_11: "Windows 11",
                linux: "Linux",
                windows_server_19: "Windows Server 19",
                windows_server_22: "Windows Server 22",
                ms_exchange: "MS Exchange",
                ms_sql: "MS SQL"
            }
        }
    },
    methods: {
        getOsName(os) {
            return os.length !== 0 ? this.osNames[os] : "";
        }
    }
}

export const imageUrl = {
    computed: {
        imageUrlPrefix () {
            return window.configuratorUrl;
        }
    }
}


// export default {
//     dialog,
//     os,
//     imageUrl
// };
<template>
    <div style="display: flex; position: sticky; align-items: center; justify-content: center; height: 50px;">
        <span class="mitText" style="margin-right: 5px;">Mitteldeutsche IT</span>
        <img src="../assets/mitteldeutsche_logo_circle.svg" width="20" height="20">
        <span style="margin-left: 5px;">Partner Portal</span>
        <a href="https://mitteldeutsche-it.de/impressum/" target="_blank" style="font-weight: normal;">&nbsp;| Impressum </a>
        <a href="https://mitteldeutsche-it.de/wp-content/uploads/2022/08/Datenschutz.pdf" target="_blank" style="font-weight: normal;">&nbsp;| Datenschutz</a>
    </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
    a { text-decoration: none;  }
    @media (max-width: 451px) {
        .mitText {
            display:none;
        }
    }
</style>
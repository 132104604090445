<template>
    <a-spin :spinning="loading">
        <a-modal
        style="height: 50em;"
        v-model:open="showCreateUser"
        :title="'Benutzer erstellen'"
        :closable="false"
        :maskClosable="false"
        cancelText="Abbrechen"
        @cancel="closeModal"
        @ok="handleUserSubmit"
        destroyOnClose
        :confirmLoading="loading"
        centered>
        <a-form
            ref="formRefCreateUser"
            :model="formCreateUser"
            :rules="rulesCreateUser"
            :loading="loading"
            layout="vertical"
            @finish="handleUserSubmit">
            <!-- WP Implementation -->
            <a-form-item ref="userName" name="userName">
            <template #label>
                <TooltipLabel :title="'Name des Benutzers'" :tooltip="'Name des eigentlichen Benutzer, welches vom System benutzt wird. Keine Sonderzeichen erlaubt um Requestfehler zu verhindern.'"/>
            </template>
            <a-input v-model:value="formCreateUser.userName" :placeholder="'Name des Benutzer'" />
            </a-form-item>
            <a-row :gutter="12">
            <a-col :md="24" :lg="12">
                <a-form-item ref="userPassword" name="userPassword">
                <template #label>
                    <tooltip-label :title="'Passwort'" :tooltip="'Mindestanforderungen: \n- Mindestens 8 Zeichen\n- Mindestens 1 Großbuchstaben\n- Mindestens 1 Kleinbuchstaben\n- Mindestens 1 Zahl\n- Mindestens 1 Sonderzeichen : \n\t!?@#$%^&*()\-_=+{};:,<.>\n- Keine Buchstabe darf mehr als zweimal hintereinander stehen!'"/>
                </template>
                <a-input-password v-model:value="formCreateUser.userPassword" :placeholder="'Passwort'"/>
                </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
                <a-form-item ref="userConfirmPassword" name="userConfirmPassword">
                <template #label>
                    <TooltipLabel :title="'Passwort bestätigen'" :tooltip="'Bitte wiederholen Sie das Passwort.'"/>
                </template>
                <a-input-password v-model:value="formCreateUser.userConfirmPassword" :placeholder="'Passwort bestätigen'"/>
                </a-form-item>
            </a-col>
            </a-row>
            <!-- WP Implementation -->
            <!-- City -->
            <a-row :gutter="12">
            <!-- Email -->
            <a-col :md="24" :lg="12">
                <a-form-item ref="userEmail" name="userEmail">
                <template #label>
                    <tooltip-label :title="'E-Mail'" :tooltip="'Bitte geben Sie die E-Mail des Nutzers an.\nEs muss eine gültige Email sein.\nErlaubte Zeichen: \nBuchstaben, Zahlen, -_@.'"/>
                </template>
                <a-input
                    v-model:value="formCreateUser.userEmail"
                    :placeholder="'E-Mail'" />
                </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="12">
            <a-col :md="24" :lg="12">
                <a-form-item ref="userFirstname" name="userFirstname">
                <template #label>
                    <tooltip-label :title="'Vorname'" :tooltip="'Bitte geben Sie den Vornamen des Nutzers an.'"/>
                </template>
                <a-input
                    v-model:value="formCreateUser.userFirstname"
                    :placeholder="'Vorname'" />
                </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
                <a-form-item ref="userLastname" name="userLastname">
                <template #label>
                    <tooltip-label :title="'Nachname'" :tooltip="'Bitte geben Sie den Nachnamen des Nutzers an.'"/>
                </template>
                <a-input
                    v-model:value="formCreateUser.userLastname"
                    :placeholder="'Nachname'" />
                </a-form-item>
            </a-col>
            </a-row>
        </a-form>
        </a-modal>
    </a-spin>
</template>

<script setup>

//IMPORTS
import api from '@/apis/apis'
import { ref, reactive, toRaw, computed, watch } from 'vue'
import TooltipLabel from '@/components/TooltipLabel.vue'
import { notification } from 'ant-design-vue'

//VARIABLES
const emailRegex                    = /^[^@]+@[^@]{2,}\.[^@]{2,}$/
const passwordRegexUpperCase        = /^(?=.*?[A-Z]).*$/ //minimum 1 Upper Case Letter
const passwordRegexLowerCase        = /^(?=.*?[a-z]).*$/ //minimum 1 Lower Case Letter
const passwordRegexDigit            = /^(?=.*?\d).*$/ //minimum 1 Digit
const passwordRegexSpecialCharacter = /^(?=.*?[!?@#$%^&*()\-_=+{};:,<.>]).*$/ //minimum 1 Special Character
const passwordRegexRepeatCharacter  = /^(?:(.)(?!\1{2}))+$/ //not more than 2 repeated Letters
const passwordRegexWrongSpecials    = /[`´€µ°²³§[\]'"|,/ß~]/ // special Characters that are not allowed
const passwordRegexLength           = /^([A-Za-z0-9!?@#$%^&*()\-_=+{};:,<.>]{8,})$/ //minimum 8 Chars
const specialChars                  = /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/
const specialCharsMail              = /[-_@.]/
const loading                       = ref(false)
const showCreateUser                = ref(false)
const formRefCreateUser             = ref()

const formCreateUser =  reactive({
    userName: undefined,
    userEmail: undefined,
    userPassword: undefined,
    userConfirmPassword: undefined,
    userFirstname: undefined,
    userLastname: undefined
})

const rulesCreateUser = reactive({
    userName: [
        { required: true, message: 'Bitte geben Sie einen Benutzernamen an.' },
        { validator: validateAccountName }
    ],
    userEmail: [
        { required: true, message: '' },
        { validator: validateEmail }
    ],
    userFirstname: [{ required: true, message: 'Bitte geben Sie den Vornamen des Nutzers an.' }],
    userLastname: [{ required: true, message: 'Bitte geben Sie den Nachnamen des Nutzers an.' }],
    userPassword: [
        { required: true, message: '' },
        { validator: validateUserPassword }
    ],
    userConfirmPassword: [
        { required: true, message: '' },
        { validator: validateConfirmUserPassword }
    ],
})

//PROPS
const props = defineProps ({
    createUserModal: {
        type: Boolean,
        default: false
    },
    openAccount: {
        type: String,
        default: ""
    },
    openDomainUuid: {
        type: String,
        default: ""
    }
})

//WATCH
const userModal = computed(() => props.createUserModal)
watch(userModal, (newVal) => {
    if ( newVal === true) {
        emit('reload-users', props.openAccount, props.openDomainUuid)
    }
    showCreateUser.value = newVal
})

//EMITS
const emit = defineEmits(['reload-users', 'close-modal']);

//FORMS
const initForm = () => {
    formRefCreateUser.value = null
}

initForm()
    
//FUNCTIONS
function handleUserSubmit (e) {
    e.preventDefault()
    if (loading.value) return
    formRefCreateUser.value.validate().then(async () => {
        const values = toRaw(formCreateUser)
        loading.value = true

        var params = new FormData()
        params.append('username', values.userName)
        params.append('password', values.userPassword)
        params.append('email', values.userEmail)
        params.append('firstname', values.userFirstname)
        params.append('lastname', values.userLastname)
        params.append('accountname', props.openAccount)
        params.append('domainuuid', props.openDomainUuid)

        await api.createUser(params).then(response => {
            closeModal()
            loading.value = false
            notification.success({
                message: 'Erfolg',
                description: 'Der Benutzer wurde erfolgreich erstellt.',
                duration: 6
            })
            emit('reload-users', props.openAccount, props.openDomainUuid)
            }).catch(error => {
                console.log(error)
                closeModal()
                loading.value = false
                notification.error({
                    message: 'Fehler beim Erstellen des Benutzers.',
                    description:
                        'Beim Erstellen eines Benutzers ist ein Fehler aufgetreten, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
                    duration: 0
                })
                })
            }).catch(error => {
                console.log(error)
            })
}

async function listUsersForAccounts(accountNameRecord,domainIdRecord){
    let params = new FormData()
    params.append('accountName', accountNameRecord)
    params.append('domainuuid',domainIdRecord)
    await api.listUsers(params).then(response =>{
        userData = []
        userData = response.data
            }).catch(error => {
                userData = []
                console.log("error users: ",error)
            })
}

function closeModal () {
    formCreateUser.userName = undefined
    formCreateUser.userEmail = undefined
    formCreateUser.userPassword = undefined
    formCreateUser.userConfirmPassword = undefined
    formCreateUser.userFirstname = undefined
    formCreateUser.userLastname = undefined
    emit('close-modal')
}

function validateAccountName (rule, value) {
    var errorMessage = 'Der Benutzername erfüllt nicht alle Anforderungen!'
    var validated = true;
    if (!specialChars.test(value)) {
        validated = false;
        errorMessage += "\nSonderzeichen sind nicht erlaubt!"
    }
    if (value==undefined || value.length < 4 ) {
        validated = false;
        errorMessage += "\nDer Benutzername muss mindestens 4 Zeichen lang sein!"
    }
    if (validated){
        return Promise.resolve()
    } else {
        return Promise.reject(errorMessage)
    }
}

function validateEmail (rule, value) {
    const errorMessage = 'Bitte geben Sie eine gültige E-Mail an. \nBeispiel: Beispiel@Email1.com\nNur Zahlen, Buchstaben und -_@. sind erlaubt!'
    if (emailRegex.test(value) && specialCharsMail.test(value)) {
        return Promise.resolve()
    }
    return Promise.reject(errorMessage)
}

async function validateUserPassword (rule, value) {
    var errorMessage = 'Ihr Passwort erfüllt nicht alle Anforderungen!'
    var validated = true;
    if (!passwordRegexLength.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 8 Zeichen"
    }
    if (!passwordRegexUpperCase.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Großbuchstaben"
    }
    if (!passwordRegexLowerCase.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Kleinbuchstaben"
    }
    if (!passwordRegexDigit.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Zahl"
    }
    if (!passwordRegexSpecialCharacter.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Sonderzeichen"
    }
    if (passwordRegexWrongSpecials.test(value)) {
        validated = false;
        errorMessage += "\nFalsche Sonderzeichen!"
    }
    if (!passwordRegexRepeatCharacter.test(value)) {
        validated = false;
        errorMessage += "\nKeine Buchstabe darf sich nicht mehr als zweimal wiederholen!"
    }
    if (value==undefined || formCreateUser.userName==undefined || value.toLowerCase().includes(formCreateUser.userName.toLowerCase())){
        validated = false;
        errorMessage += "\nDas Passwort darf den Benutzername nicht beinhalten!"
    }
    if (validated){
        return Promise.resolve()
    } else {
        return Promise.reject(errorMessage)
    }
}

async function validateConfirmUserPassword (rule, value) {
    if (!value || value.length === 0) {
        return Promise.resolve()
    } else if (rule.field === 'userConfirmPassword') {
        const messageConfirm = 'Die Passwortfelder stimmen nicht überein'
        const passwordVal = formCreateUser.userPassword
        if (passwordVal && passwordVal !== value) {
            return Promise.reject(messageConfirm)
        } else {
            return Promise.resolve()
        }
    } else {
        return Promise.resolve()
    }
}

</script>
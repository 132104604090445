<template>
  <div>
    <img src="../assets/mittelDeutsche_header_banner2.svg" width="236" height="50" alt="mIT">
    <div>Redirecting...</div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import api from '@/apis/apis'; // Importiere deine API
import { useRouter } from 'vue-router'; // Importiere useRouter
import store from '@/store'

//VARIABLES
const cookieResult = ref('')
const router = useRouter()

//LIFECYCLE HOOKS
onMounted(async () => {
  await fetchData()
})

//FUNCTIONS
async function fetchData() {
  await setloginCookiePortal()
  if (cookieResult.value === true) {
    await loginPortal()
  }
}

async function loginPortal() {
  try {
    const response = await api.getAccountByCookie();
    document.cookie = "account=" + response.data.account + ";SameSite=None;Secure"
    store.commit('setAccountUuid', response.data.account)
    document.cookie = "authenticated=true;SameSite=None;Secure"
    router.push({ path: '/globallayout' })
  } catch (error) {
    console.log("Status Error: " + error.response.status + " " + error.response.statusText)
    document.cookie = "authenticated=false;SameSite=None;Secure"
    window.location.href = "https://login.mitteldeutsche-it.de/"
  }
}

async function setloginCookiePortal() {
  try {
    const response = await api.setCookieLocally()
    document.cookie = "session_token=" + response.data + ";SameSite=None;Secure"
    document.cookie = "authenticated=true;SameSite=None;Secure"
    cookieResult.value = true
  } catch (error) {
    console.log("Status Error: " + error.response.status + " " + error.response.statusText)
    cookieResult.value = false
    document.cookie = "session_token=;SameSite=None;Secure"
    document.cookie = "authenticated=false;SameSite=None;Secure"
    window.location.href = "https://login.mitteldeutsche-it.de/"
  }
}
</script>
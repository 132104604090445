<template>
    <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <div>Endpoint</div>
        <a-switch style="width: 70px;" checked-children="Gruppe" un-checked-children="Einzeln" v-model:checked="model"></a-switch>
    </div>
    <div>
    <br>
    <strong>Endpoints:</strong>
    <div v-if="selectedServer.endpointType === 'Gruppe'" style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
      <span style="text-align: left; width: 212px;">
        <span v-html="'Endpoints'"></span>&nbsp;
      </span>
      <a-input
        type="number"
        :class="['inputNumberField']"
        v-model:value="Endpoints"
        :min="1"
        :max="9999"
      ></a-input>
    </div>
    <div v-if="selectedServer.endpointType === 'Einzeln'" style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
      <span style="text-align: left; width: 212px;">
        <span v-html="'Endpoints'"></span>&nbsp;
      </span>
      1
    </div>
    <div>
      <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px; display: flex; flex-direction: row;">
          <span v-html="'Backup-Speicher in GB'"></span>&nbsp;
          <div style="display: flex; flex-direction: row; justify-content: center;">
            <a-popover :title="'Backup-Speicher:'">
              <template #content>
                <span>{{'Der Backup-Speicher bezieht sich auf den gewünschten Speicher für'}}</span><br>
                <span>{{'alle darüber ausgewählten Endpoints.'}}</span>
              </template>
              <ExclamationCircleOutlined />
            </a-popover>
          </div>
        </span>
        <a-input
          type="number"
          :class="['inputNumberField']"
          v-model:value="backupStorage"
          :min="0"
          :max="99999"
        ></a-input>
      </div>
    </div>
  </div>
  </template>
  
  <script setup>

  //IMPORTS
  import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
  import { computed } from 'vue'
  import store from '@/store'

  //PROPS
  const props = defineProps({
    serverType: {
      type: String,
      required: true
    },
  })

  //COMPUTED PROPERTIES
  const selectedServer = computed(() => store.getters.selectedServer);

  const model = computed({
    get() {
      if (selectedServer.value.endpointType === 'Einzeln'){
        updateConfig("Cyber_Cloud_Endpoint", 1);
        return false 
      }
      if (selectedServer.value.endpointType === 'Gruppe'){
        return true 
      }
    },

    set(value) {
      let server = selectedServer.value;
      if (value === true) {
        if (server.name.includes('Cyber Cloud Endpoint')){
          selectedServer.value.name = server.name.replace("Endpoint", "Endpoint Gruppe")
        }    
        selectedServer.value.endpointType = "Gruppe"
      }
      if (value === false) {
        if (server.name.includes('Cyber Cloud Endpoint Gruppe')){
          selectedServer.value.name = server.name.replace("Endpoint Gruppe", "Endpoint")
        }    
        selectedServer.value.endpointType = "Einzeln"
      }  
     }
  })

  const Endpoints = computed({
    get() {
      return selectedServer.value.configuration["Cyber_Cloud_Endpoint"]
    },

    set(value) {
      if (value > 999) {
        updateConfig("Cyber_Cloud_Endpoint", 999);
      } else if (value < 1) {
        updateConfig("Cyber_Cloud_Endpoint", 1);
      } else {
        updateConfig("Cyber_Cloud_Endpoint",  parseInt(value));
      }
    }
  })

  const backupStorage = computed({
    get() {
      return selectedServer.value.configuration["AcronisRBS"]
    },

    set(value) {
      if (value > 99999) {
        updateConfig("AcronisRBS", 99999);
      } else {
        updateConfig("AcronisRBS",  parseInt(value));
      }      
    }
  })

  //FUNCTIONS
  function setSelectedConfiguration (configuration) {
    store.commit('setSelectedConfiguration', configuration)
  }

  function updateConfig(attribute, value) {
    let server = selectedServer.value;
    server.configuration[attribute] = value;  
    setSelectedConfiguration(server.configuration);
  }
  </script>
  
  <style lang="scss" scoped>
  .inputNumField {
    width: 60px;
    margin-top: 5px;
  }
  .inputFieldRequired {
    width: 60px;
    margin-top: 5px;
    border-color: red;
    border-width: 2px;
  }
  .sideBarElementLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
  }
  .collapseHeader {
    display: flex;
    text-align: left;
  }
  .disabled {
    opacity: 0.5;
  }
  .inputNumberField {
    width: 60px;
  }
  .ant-radio-button-wrapper{
    width: 100%;
    border-radius: 50px;
    background-color: rgb(233, 231, 231);
    margin-bottom: 5px;
  }
  .ant-radio-button-wrapper:hover{
    background-color: rgb(207, 205, 205);
  }
  .ant-radio-button {
    width: 100%;
    background-color: rgb(233, 231, 231);
    margin-bottom: 5px;
  }
  .clickable {
    cursor: pointer;
  
    &:hover {
      color: #d02c2a;
    }
  }
  .os-buttons {
    width: 100%;
    .btn {
      border-radius: 50rem;
      width: 100%;
      margin: 0.25rem 0 !important;
      text-align: left;
      &.active {
        z-index: 0;
      }
      &,
      & > div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  .os-field {
    background-color: #fff;
    color: #000;
    margin-bottom: 10px;
  }

  </style>
<template>
  <div>
    <div class="offer-info" v-if="$store.getters.getImportedConfigName">
      <div class="offer-info-title">{{ 'Angebot in Bearbeitung: ' }}</div>
      <div>Angebot: {{ $store.getters.getImportedConfigName }}</div>
      <div v-if="$store.getters.getImportedConfigNumber">Angebotsnummer: {{
          $store.getters.getImportedConfigNumber
        }}
      </div>
      <div v-else>Angebotsnummer: -</div>
    </div>
    <div class="kdz-sidebar-head px-2 py-4">
      <div>Kosten&shy;übersicht</div>
    </div>
    <div class="kdz-cost-list">
      <!-- loop over all 5 servertypes -->
      <template v-for="(type, indexServerTypes) in serverTypes">
        <div
            v-if="checkServerType(type) || (type=='iaas' && ($store.getters.firewall || $store.getters.ip > 0)) || (type=='wpCloud' && $store.getters.firewall2)"
            class="serverTypeHeader">{{ serverTypeNames[serverTypes[indexServerTypes]] }}
        </div>
        <!-- special cases regarding ips and firewall for iaas -->
        <template v-if="type== 'iaas'">
          <!-- iaas firewall active and ips selected -->
          <template v-if="$store.getters.firewall && $store.getters.ip > 0">
            <div
                :class="['kdz-last-element', 'kdz-cost-border']"
            >
              <a-popover placement="left"
                         :title="bill[0].bill[0].hoverLabel + ' = ' + bill[0].bill[0].singlePrice.toString().replace('.' , ',') + '€ '"
                         v-if="windowWidth>790">
                <template #content>
                  <span>{{ bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')') }}</span>
                </template>
                <span class="hoverLabel">{{ bill[0].bill[0].label }}</span>
              </a-popover>
              <a-popover placement="top"
                         :title="bill[0].bill[0].hoverLabel + ' = ' + bill[0].bill[0].singlePrice.toString().replace('.' , ',') + '€ '"
                         v-if="windowWidth<=790">
                <template #content>
                  <span>{{ bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')') }}</span>
                </template>
                <span class="hoverLabel">{{ bill[0].bill[0].label }}</span>
              </a-popover>
              <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right"
                    align-self="center">
                    <i>{{
                        bill[0].bill[0].price.toString().replace('.', ',')
                      }}</i> &euro;&nbsp;{{ '(' + bill[0].bill[0].discount + '%)' }}
                    </span>
            </div>
            <div
                :class="['kdz-last-element', 'kdz-cost-border']"
            >
              <a-popover placement="left"
                         :title="bill[1].bill[0].hoverLabel + ' = ' + ($store.getters.firewall2 ? (bill[1].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[1].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '"
                         v-if="windowWidth>790">
                <template #content>
                  <span>{{ bill[1].bill[0].UsageForm + 'Netto' + (bill[1].bill[0].discount > 0 ? ', inkl. ' + bill[1].bill[0].discount + '% Rabatt)' : ')') }}</span>
                </template>
                <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <a-popover placement="top"
                         :title="bill[1].bill[0].hoverLabel + ' = ' + ($store.getters.firewall2 ? (bill[1].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[1].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '"
                         v-if="windowWidth<=790">
                <template #content>
                  <span>{{ bill[1].bill[0].UsageForm + 'Netto' + (bill[1].bill[0].discount > 0 ? ', inkl. ' + bill[1].bill[0].discount + '% Rabatt)' : ')') }}</span>
                </template>
                <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right"
                    align-self="center">
                    <i>{{
                        $store.getters.firewall2 ? (bill[1].bill[0].price / 2).toFixed(3).toString().replace('.', ',') : bill[1].bill[0].price.toString().replace('.', ',')
                      }}</i> &euro;&nbsp;{{ '(' + bill[1].bill[0].discount + '%)' }}
                    </span>
            </div>
          </template>
          <!-- iaas either firewall or ips are slected -->
          <template v-else-if="$store.getters.ip > 0 || $store.getters.firewall">
            <div
                :class="['kdz-last-element', 'kdz-cost-border']"
            >
              <a-popover placement="left"
                         :title="bill[0].bill[0].hoverLabel + ' = ' + bill[0].bill[0].singlePrice.toString().replace('.' , ',') + '€ '"
                         v-if="windowWidth>790">
                <template #content>
                  <span>{{ bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')') }}</span>
                </template>
                <span class="hoverLabel">{{
                    $store.getters.ip > 0 ? bill[0].bill[0].label : (bill[0].bill[0].label.replace('x2', 'x1'))
                  }}</span>
              </a-popover>
              <a-popover placement="top"
                         :title="bill[0].bill[0].hoverLabel + ' = ' + bill[0].bill[0].singlePrice.toString().replace('.' , ',') + '€ '"
                         v-if="windowWidth<=790">
                <template #content>
                  <span>{{ bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')') }}</span>
                </template>
                <span class="hoverLabel">{{
                    $store.getters.ip > 0 ? bill[0].bill[0].label : (bill[0].bill[0].label.replace('x2', 'x1'))
                  }}</span>
              </a-popover>
              <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right"
                    align-self="center">
                    <i>{{ $store.getters.ip > 0 ? bill[0].bill[0].price.toString().replace('.', ',') : ($store.getters.firewall2 ? (bill[0].bill[0].price / 2).toFixed(3).toString().replace('.', ',') : bill[0].bill[0].price.toString().replace('.', ',')) }}</i> &euro;&nbsp;{{
                  '(' + bill[0].bill[0].discount + '%)'
                }}
                    </span>
            </div>
          </template>
        </template>
        <!-- special case regarding firewall wpCLoud -->
        <template v-if="type== 'wpCloud'">
          <!-- wpcloud firewall active and ips selected -->
          <template v-if="$store.getters.firewall2 && $store.getters.ip > 0">
            <div
                :class="['kdz-last-element', 'kdz-cost-border']"
            >
              <a-popover placement="left"
                         :title="bill[1].bill[0].hoverLabel + ' = ' + ($store.getters.firewall ? (bill[1].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[1].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '"
                         v-if="windowWidth>790">
                <template #content>
                  <span>{{ bill[1].bill[0].UsageForm + 'Netto' + (bill[1].bill[0].discount > 0 ? ', inkl. ' + bill[1].bill[0].discount + '% Rabatt)' : ')') }}</span>
                </template>
                <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <a-popover placement="top"
                         :title="bill[1].bill[0].hoverLabel + ' = ' + ($store.getters.firewall ? (bill[1].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[1].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '"
                         v-if="windowWidth<=790">
                <template #content>
                  <span>{{ bill[1].bill[0].UsageForm + 'Netto' + (bill[1].bill[0].discount > 0 ? ', inkl. ' + bill[1].bill[0].discount + '% Rabatt)' : ')') }}</span>
                </template>
                <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right"
                    align-self="center">
                    <i>{{
                        $store.getters.firewall ? (bill[1].bill[0].price / 2).toFixed(3).toString().replace('.', ',') : bill[1].bill[0].price.toString().replace('.', ',')
                      }}</i> &euro;&nbsp;{{ '(' + bill[1].bill[0].discount + '%)' }}
                    </span>
            </div>
          </template>
          <!-- wpCloud firewall active and ips unselected -->
          <template v-else-if="$store.getters.firewall2">
            <div
                :class="['kdz-last-element', 'kdz-cost-border']"
            >
              <a-popover placement="left"
                         :title="bill[0].bill[0].hoverLabel + ' = ' + ($store.getters.firewall ? (bill[0].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[0].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '"
                         v-if="windowWidth>790">
                <template #content>
                  <span>{{ bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')') }}</span>
                </template>
                <span class="hoverLabel">{{ bill[0].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <a-popover placement="top"
                         :title="bill[0].bill[0].hoverLabel + ' = ' + ($store.getters.firewall ? (bill[0].bill[0].singlePrice / 2).toFixed(3).toString().replace('.' , ',') : bill[0].bill[0].singlePrice.toString().replace('.' , ',')) + '€ '"
                         v-if="windowWidth<=790">
                <template #content>
                  <span>{{ bill[0].bill[0].UsageForm + 'Netto' + (bill[0].bill[0].discount > 0 ? ', inkl. ' + bill[0].bill[0].discount + '% Rabatt)' : ')') }}</span>
                </template>
                <span class="hoverLabel">{{ bill[0].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right"
                    align-self="center">
                    <i>{{
                        $store.getters.firewall ? (bill[0].bill[0].price / 2).toFixed(3).toString().replace('.', ',') : bill[0].bill[0].price.toString().replace('.', ',')
                      }}</i> &euro;&nbsp;{{ '(' + bill[0].bill[0].discount + '%)' }}
                    </span>
            </div>
          </template>
        </template>
        <template v-for="(pos, index) in bill" :key="index + pos.serverName">
          <!-- sort after the servertypes, special case for containerIaaS -->
          <template v-if="pos.type === type || (pos.type=='containerIaaS' && type == 'iaas')">
            <!-- serverHeader unfolded-->
            <template v-if="showCosts.includes(pos.id) || selectedServer.id == pos.id">
              <div class="serverHeader"
                   @click="showCosts.splice(showCosts.indexOf(pos.id), 1)">
                <strong v-html="pos.serverName.includes('()') ? pos.serverName.replace(' <small>()</small>','') : pos.serverName"></strong>
                <div style="text-align: right; flex-grow: 4;">
                  <UpOutlined/>
                </div>
              </div>
            </template>
            <!-- serverHeader folded -->
            <template v-else>
              <div :class="['serverHeader']" style="display: flex; cursor: pointer;"
                   @click="showCosts.push(pos.id)">
                <strong
                    v-html="pos.serverName.includes('()') ? pos.serverName.replace(' <small>()</small>','') : pos.serverName"></strong>
                <div style="text-align: right; flex-grow: 4;">
                  <DownOutlined/>
                </div>
              </div>
            </template>
            <!-- invitems unfolded-->
            <template v-for="(invItem, ind) in pos.bill">
              <template v-if="showCosts.includes(pos.id) || selectedServer.id == pos.id">
                <div
                    :key="ind + '_' + index + pos.serverName"
                    v-if="invItem.type !== 'network'"
                    :class="[{ 'kdz-last-element': (ind === pos.bill.length - 1)  },{ 'kdz-last-element': (ind === pos.bill.length - 2 && pos.type === 'containerIaaS')  }, 'kdz-cost-border']"
                >
                  <!-- Regarding the classes: Because of the network type skip and the last element of containerIaaS are networks, we need a special case -->
                  <a-popover placement="left"
                             :title="invItem.hoverLabel + ' = ' + invItem.singlePrice.toString().replace('.' , ',') + '€ '"
                             v-if="windowWidth>790">
                    <template #content>
                      <span>{{ invItem.UsageForm + 'Netto' + (invItem.discount > 0 ? ', inkl. ' + invItem.discount + '% Rabatt)' : ')') }}</span>
                    </template>
                    <span class="hoverLabel">{{ invItem.label }}</span>
                  </a-popover>
                  <a-popover placement="top"
                             :title="invItem.hoverLabel + ' = ' + invItem.singlePrice.toString().replace('.' , ',') + '€ '"
                             v-if="windowWidth<=790">
                    <template #content>
                      <span>{{ invItem.UsageForm + 'Netto' + (invItem.discount > 0 ? ', inkl. ' + invItem.discount + '% Rabatt)' : ')') }}</span>
                    </template>
                    <span class="hoverLabel">{{ invItem.label }}</span>
                  </a-popover>
                  <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right"
                        align-self="center">
                        <i>{{
                            invItem.price.toString().replace('.', ',')
                          }}</i> &euro;&nbsp;{{ '(' + invItem.discount + '%)' }}
                    </span>
                </div>
              </template>
            </template>
            <!-- invtitem -->
          </template>
          <!-- servetype -->
        </template>
      </template>
    </div>

    <div class="kdz-cost-sum">
      <div class="partner-select-wrapper">
        <span>Partner Status:</span>
        <a-select
            id="houseInput"
            v-model:value="houseSelect"
        >
          <a-select-option v-for="option in houseOptions" :key="option.partnerValue">
            {{ option.text }}
          </a-select-option>
        </a-select>
      </div>

      <div class="sum-pos">
        <span> Gesamtkosten / Monat <small>(Preise exkl. Mwst.)</small></span><br>
        <strong class="cost-summary-right">Summe: <span class="text-right">{{ summary[1].toString().replace(".", ",") }} €</span></strong>
        <strong class="cost-summary-right"><span>mit {{ partnerDiscount }} % Rabatt*<tooltip-label :myColor="'white'"
                                                              :tooltip="'Partner-Rabatte beziehen sich auf folgende Resourcen: vCPU, RAM, IP-Adressen, GPU, TSPlus, SSD, HDD und Next-Generation-Firewall.'"/>:</span> <span class="text-right">{{ summary[0].toString().replace(".", ",") }} €</span></strong>
      </div>
      <div class="sum-pos" v-if="isCloudOrIaas">
        <span>Kosten pro Stunde <small>(mntl. 730h &#8960;) <template v-if="discount > 0 && houseSelect">mit Rabatt</template></small></span><br>
        <strong class="cost-summary-right"><span>Summe:</span> <span class="text-right">{{ costPerHour.toString().replace('.', ',') }} €</span></strong>
      </div>
    </div>
  </div>
</template>

<script setup>

//IMPORTS
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import TooltipLabel from '@/components/label/TooltipLabel.vue'
import { ref, computed } from 'vue'
import store from '@/store'

//VARIABLES
const serverTypes = ["container", "cloudServer", "iaas", "wpCloud", "cybercloudendpoint"]
const serverTypeNames = {
  container: "Container Deployments",
  cloudServer: "Cloud-Server",
  iaas: "Infrastructure as a Service",
  wpCloud: "Online Office",
  cybercloudendpoint: "Cyber Cloud Endpoint"
}

const showCosts = ref([])
const windowWidth = ref(window.innerHeight)
const houseOptions = ref([
  { partnerValue: "Standard", text: "Standard" },
  { partnerValue: "Holz", text: "Reseller (Holz)" },
  { partnerValue: "Bronze", text: "Reseller (Bronze)" },
  { partnerValue: "Silber", text: "Reseller (Silber)" },
  { partnerValue: "Gold", text: "Reseller (Gold)" },
  { partnerValue: "Platin", text: "Reseller (Platin)" }
])

//COMPUTED PROPERTIES
const bill = computed(() => store.getters.bill);
const selectedServer = computed(() => store.getters.selectedServer);
const serverList = computed(() => store.getters.serverList);
const discount = computed(() => store.getters.discount);
const discountType = computed(() => store.getters.discountType);
const isSidebarOpen = computed(() => store.getters.isSidebarOpen);
const partnerDiscount = computed(() => store.getters.partnerDiscount);

const houseSelect = computed({
  get() {
    return discountType.value;
  },
  set(value) {
    let selectedDiscount;

    // Setze den Discount-Typ im Store
    store.commit('setDiscountType', value);

    switch (value) {
      case 'Holz':
        selectedDiscount = 20;
        break;
      case 'Bronze':
        selectedDiscount = 30;
        break;
      case 'Silber':
        selectedDiscount = 35;
        break;
      case 'Gold':
        selectedDiscount = 40;
        break;
      case 'Platin':
        selectedDiscount = 47;
        break;
      default:
        selectedDiscount = 0;
    }

    // Committe den Partner-Rabatt im Store
    store.commit("setPartnerDiscount", selectedDiscount);

    // Aktualisiere die Serverkonfiguration, falls vorhanden
    const servers = serverList.value; // Hole die Serverliste

    if (servers.length > 0) {
      for (let server of servers) {
        if (Object.keys(server.configuration).length > 0) {
          const before = server.configuration.vCPU; // Speichere den aktuellen Wert von vCPU
          server.configuration.vCPU = 1; // Setze vCPU auf einen Standardwert
          server.configuration.vCPU = before; // Setze es zurück
        }
      }
    }
  }
})

const isCloudOrIaas = computed(() => {
  const selectedServerType = store.getters['selectedServer'].type;
  const serverList = store.getters['serverList'];

  return (
    selectedServerType === "iaas" ||
    selectedServerType === "cloudServer" ||
    (serverList.length > 0 && 
      (serverList[0].type === "cloudServer" || 
       serverList[0].type === "iaas"))
  )
})

const summary = computed(() => {
  let sum = 0.0
  let originalSum = 0.0

  const billItems = store.getters.bill; // Hole die Rechnungsdaten

  billItems.forEach((el) => {
    for (let pos of el.bill) {
      sum += Number(pos.price)
      if (pos.originalPrice !== undefined) {
        originalSum += Number(pos.originalPrice)
      }
    }
  })

  return [sum.toFixed(2), originalSum.toFixed(2)]
})

const costPerHour = computed(() => {
    return ((summary.value[0]) / 730).toFixed(2)
})


//FUNCTIONS
function checkServerType (type) {
  for (let i = 0; i < bill.value.length; i++) {
    if (
      bill.value[i].type !== undefined &&
      (bill.value[i].type === type ||
        (bill.value[i].type === "containerIaaS" && type === "iaas"))
    ) {
      return true;
    }
  }
  return false;
}

</script>

<style lang="scss">
.cost-summary-right {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.cost-summary-right .text-right {
  text-align: right;
}
.sum-pos {
  text-align: left;
  margin-bottom: 6px;
  font-family: "Open Sans", sans-serif;
  width: 100%;
}
.partner-select-wrapper {
  display: grid;
  grid-template-columns: 120px 1fr;
  justify-content: space-between;
  text-align: left;
  align-content: center;
  align-items: center;
  margin-bottom: 6px;
  width: 100%;
  &> span {
    font-weight: bold;
  }
  .ant-select {
    width: 100%;
    //margin-bottom: 10px;
  }
}
.offer-info {
  font-size: 16px;
  text-align: left;
  padding: 0 19px;
  margin-bottom: 10px;
  &-title {
    font-weight: bold;
  }
}
</style>
<template>
  <div class="kdz-cost-wrapper">
    <div
        fluid
        :class="[
        'kdz-costs text-white',
        (discountType === 'Holz' ? 'woodPartner' : (discountType === 'Bronze' ? 'bronzePartner' : (discountType === 'Silber' ? 'silverPartner' : (discountType === 'Gold' ? 'goldPartner' : (discountType === 'Platin' ? 'platinumPartner' : 'standardPartner'))))),
      ]"
        style="text-align: right;"
    >
      <div class="kdz-costs-main">
      <a-tabs v-model:activeKey="activeKey" type="card" class="costoverview" :tabBarGutter="0">
        <a-tab-pane :key="'1'">
          <template #tab>
            <a-popover>
              <span>Einkaufspreis</span>
              <template #content>
                <span>{{ 'Kalkulation' }}</span>
              </template>
            </a-popover>
          </template>
          <!-- EK -->
          <EK @openCancelConfigModal="openCancelConfigModal"/>
          <!--  -->
        </a-tab-pane>
        <a-tab-pane :key="'2'">
          <template #tab>
            <a-popover>
              <span>Verkaufspreis</span>
              <template #content>
                <span>{{ 'Kundenangebot' }}</span>
              </template>
            </a-popover>
          </template>
          <!-- VK -->
          <VK @openCancelConfigModal="openCancelConfigModal"/>
          <!--  -->
        </a-tab-pane>
      </a-tabs>
      <div class="kdz-sidebar-action" >
        <a-popover v-if="isSidebarOpen || bill.length === 0 || (bill.length === 1 && $route.path === '/iaas')"
                   placement="top">
          <template #content>
              <span>{{
                  'Schließen Sie Ihre aktuelle Konfiguration ab. Zum Speichern eines Angebots muss min. ein Server konfiguriert sein!'
                }}</span>
          </template>
          <a-button id="saveConfBtn1" type="primary" size="default"
                    @click="saveConfig()"
                    :disabled="isSidebarOpen || bill.length === 0 || (bill.length === 1 && $route.path === '/iaas')">
            <b>Speichern</b></a-button>
        </a-popover>
        <a-button id="saveConfBtn2" v-else type="primary"
                  size="default" @click="saveConfig()"
                  :disabled="isSidebarOpen || bill.length === 0 || (bill.length === 1 && $route.path === '/iaas')">
          <b>Speichern</b>
        </a-button>
        <a-popover v-if="!configButton" placement="left">
          <template #content>
            <span>{{ 'Bitte speichern Sie das Angebot ab, bevor es herunterladen.' }}</span>
          </template>
          <a-button id="downloadOfferBtn2" type="secondary"
                    size="default" @click="getOffer()" :disabled="!configButton"><b>Herunterladen</b></a-button>
        </a-popover>
        <a-button v-else id="downloadOfferBtn"
                  type="secondary" size="default" @click="getOffer()" :disabled="!configButton"><b>Herunterladen</b>
        </a-button>
      </div>
      </div>
      <div class="kdz-sidebar-footer">
        <a-button

            @click="openCancelConfigModal"
            class="exit-button"
            type="link"
        >
          Konfiguration löschen <span class="icon-round">X</span>
        </a-button>
      </div>

      <a-modal
          :title="'Konfiguration abbrechen?'"
          v-model:open="cancelConf"
          :closable="true"
          @cancel="cancelConf = false"
          @ok="exitConfig()"
          okText="Ok"
          destroyOnClose
          cancelText="Abbrechen"
          centered>
        Wollen Sie die Konfiguration wirklich abbrechen? Alle bisher angegeben Daten werden komplett zurück gesetzt!
      </a-modal>
      <SaveConfig
          :showSaveConfig="showSaveConfig"
          :importObjectString="JSON.stringify(store.getters.serverList)"
          :billObject="store.getters.bill"
          :accountId="accountid"
          :allAccounts="accountData"
          @close-modal="closeModal"/>
    </div>
  </div>
</template>

<script setup>

//IMPORTS
import {notification} from 'ant-design-vue'
import api from '@/apis/apis'
import {ref, computed, onMounted, onBeforeUnmount} from 'vue'
import {SaveOutlined} from '@ant-design/icons-vue'
import SaveConfig from '../components/forms/SaveConfig.vue'
import EK from "./forms/EK.vue";
import VK from "./forms/VK.vue";
import store from '@/store'
import {useRouter} from 'vue-router'

//VARIABLES
const accountid = ref(getCookie('account'))
const activeKey = ref('1')
const windowWidth = ref(window.innerWidth)
const cancelConf = ref(false)
const configButton = ref(false)
const showSaveConfig = ref(false)
const loading = ref(false)
const accountData = ref([])

const bill = computed(() => store.getters.bill)
const discountType = computed(() => store.getters.discountType)
const isSidebarOpen = computed(() => store.getters.isSidebarOpen)

const route = useRouter()

//LIFECYCLE HOOKS
onMounted(() => {
  window.addEventListener('resize', onResize)
  configButton.value = false
  listAccountsForReseller()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

//FUNCTIONS
function onResize() {
  windowWidth.value = window.innerWidth
}

function saveConfig() {
  showSaveConfig.value = true
}

async function getOffer() {
  const params = new FormData()

  params.append('object', JSON.stringify(bill.value))
  params.append('account', accountid.value)
  params.append('customerId', store.getters.getCustomerId)
  params.append('offerName', store.getters.getImportedConfigName)
  params.append('offerNumber', store.getters.getImportedConfigNumber)
  params.append('customerName', store.getters.getCustomerName)

  try {
    const response = await api.getPdf(params)
    let filename = response.headers['content-disposition'].split("filename=")[1]
    let url = window.URL.createObjectURL(new Blob([response.data]))
    let link = document.createElement('a')

    link.href = url
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click()

    notification.success({message: 'Angebot wurde erstellt!'})

  } catch (error) {
    console.log(error)
  }
}

async function listAccountsForReseller() {
  loading.value = true

  accountid.value = getCookie('account')

  try {
    const response = await api.listAccounts(accountid.value)
    accountData.value = response.data

  } catch (error) {
    console.log("error account", error)

  } finally {
    loading.value = false
  }
}

function getCookie(cname) {
  let name = cname + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

function closeModal() {
  showSaveConfig.value = false
}

function exitConfig() {
  setSidebarVisibility(false)
  setCustomerId(undefined)
  setImportedConfigNumber(undefined)
  setImportedConfigName(undefined)
  clearServer()

  route.push("/prices")

  cancelConf.value = false
  configButton.value = false
}

function openCancelConfigModal() {
  cancelConf.value = true
}

function setSidebarVisibility(value) {
  store.commit("setSidebarVisibility", value)
}

function clearServer() {
  store.commit("clearServer")
}

function setImportedConfigName(value) {
  store.commit("setImportedConfigName", value)
}

function setImportedConfigNumber(value) {
  store.commit("setImportedConfigNumber", value)
}

function setCustomerId(value) {
  store.commit("setCustomerId", value)
}

</script>

<style lang="scss">

.hoverLabel {
  width: 60%;
  &:hover {
    font-weight: bold;
  }
}

.text-white {
  color: white;
  .ant-tabs.costoverview {
    color: white;
  }
}

.exit-button {
  color: #ffffff;
  display: flex;
  justify-content: flex-end;
  width: auto;
  align-items: center;
  align-content: center;
  span.icon-round {
    color: #fff;
    background: #E32426;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 8px;
    font-size: 10px;
    font-weight: bold;
  }
}

.kdz-sidebar {
  &-head {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid white;
    font-size: 24px;
    padding: 0 16px;
    input {
      margin-right: 12px;
    }
  }
  &-footer {
    width: 100%;
    padding: 2px 0 0 0;
    border-top: 1px solid #efefef;
    margin: auto 0 0;
    .exit-button {
      width: 100%;
      color: #fff;
      font-weight: 600;
      //text-shadow: 0px 0px 1px rgba(255,255,255,1);
      &:hover {
        color: #E32426;
      }
    }
  }
  &-action {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    margin-bottom: auto;
    padding: 0 10px 8px;

    .ant-btn {
      width: 100%;
      border-radius: 0;
    }

    .ant-btn.ant-btn-secondary {
      background: #4373FD;
      color: #ffffff;
      &:disabled {
        cursor: not-allowed;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 0, 0, 0.04);
        box-shadow: none;
      }
    }
  }
}
.kdz-config-form {
  .kdz-sidebar {
    &-head {
      padding: 0 10px;

      input {
        margin-right: 12px;
        font-weight: bold;
      }
      .exit-button {
        padding: 0 6px;
        .icon-round {
          background: #fff;
          color: #E32426;
          margin: 0;
          width: 20px;
          height: 20px;
          line-height: 1;
        }
      }
    }
  }
}
.cloudServerMain:not(.iaas-container):not(.backup-container) > div {
  background: transparent;
  //box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  //border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.kdz-cost {
  &-wrapper {
    height: 100%;
  }
  &-border {
    border-bottom: 1px dotted #ddd;
    display: flex;
    justify-content: space-between;
    //background-color: #F3F5F9;
  }
  &-list {
    //max-height: 600px;
    font-size: 13px;
    //overflow-y: auto;
    text-align: left;
    padding: 0 16px 0 16px;
    background-color: transparent;
  }
  &-sum {
    padding: 0.5rem 1rem;
    border-top: 2px solid #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .big {
      font-size: 25px;
      font-weight: bold;
      text-decoration: underline double;
    }

    .vkbig {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
.kdz-last-element {
  border-bottom: 1px solid #ddd;
  margin-bottom: 8px;
}

.kdz-costs {
  //height: 550px;
  width: 310px;
  overflow: auto;
  transition: 0.8s all ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 0px;
  border: none;
  position: relative;

  &.standardPartner {
    background-color: #3d7caf;
  }

  &.woodPartner {
    background-color: #9a7b4f;
  }

  &.bronzePartner {
    background-color: #cd7f32;
  }

  &.silverPartner {
    background-color: #afafaf;
  }

  &.goldPartner {
    background-color: #edc006;
  }

  &.platinumPartner {
    background-color: #a0b2c6;
  }

}
.kdz-cancel {
  display: flex;
  align-items: center;
}
.costoverview.ant-tabs-card > .ant-tabs-nav {
  .ant-tabs-tab {
    border-radius: 0px;
    width: 155px;
    font-size: large;
    color: black;
    background: #EEEEEE;
  }
  .ant-tabs-tab-active {
    background: white
  }
}

.serverTypeHeader {
  font-weight: normal;
  font-size: 20px;
  //color: #5B5A5A;
  border-bottom: 1px solid #ddd;
  margin-bottom: 6px;
}

.serverHeader {
  white-space: pre-wrap;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  //color: #5B5A5A;
  //background-color: rgba(255, 255, 255, 0.3);
  //color: rgb(255, 255, 255);
  line-height: 1.25;
  padding-bottom: 3px;
  display: flex;
  cursor: pointer;
  margin-bottom: 4px;
  small {
    font-weight: normal;
  }
}

.bottomBorderFolded {
  border-bottom: 1px solid #ddd;
}

@media (min-width: 769px) {
  .kdz-costs {
    min-height: calc(500px + 2.5rem);
    height: auto;
  }
}


@media (max-width: 768px) {
  .kdz-costs {
    height: auto;
    width: 100%;
    //padding: 12px 12px 12px 12px;
    display: flex;
    flex-direction: column;
  }
  .kdz-cost-wrapper {
    width: 100%;
  }
  .kdz-costs-main {
    width: 100%;
  }
  /*.kdz-cost-sum {
    display: flex;
    flex-direction: column;
    align-items: center;
  }*/
}
</style>

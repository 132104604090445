<template>
    <div style="width: 100%; height: calc( 100vh - (100px + 50px)); overflow-y: auto;">
      <router-view/>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>
import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import Invoices from '@/views/Invoices.vue'
import Customers from '@/views/Customers.vue'
import Prices from '@/views/Prices.vue'
import Tutorials from '@/views/Tutorials.vue'
import GlobalLayout from '@/components/GlobalLayout.vue'
import CloudServerView from '@/views/CloudServerView.vue'
import WpCloudServerView from "@/views/WpCloudServerView.vue";
import InfraAsServiceView from "@/views/InfraAsServiceView.vue";
import BackUpView from "@/views/BackUpView.vue";
import Settings from "@/views/Settings.vue";
import ContainerView from "@/views/ContainerView.vue"
import store from '@/store'

import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/globallayout',
    name: 'GlobalLayout',
    component: GlobalLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/customers',
        name: 'Customers',
        component: Customers,
      },
      {
        path: '/invoices',
        name: 'Invoices',
        component: Invoices,
      },
      {
        path: '/prices',
        name: 'Prices',
        component: Prices,
        children: [
          {
            path: '/container',
            name: 'container',
            component: ContainerView
          },
          {
            path: '/cloudserver',
            name: 'cloudServer',
            component: CloudServerView
          },
          {
            path: '/collaboration',
            name: 'collaboration',
            component: WpCloudServerView
          },
          {
            path: '/iaas',
            name: 'iaas',
            component: InfraAsServiceView
          },
          {
            path: '/cybercloudendpoint',
            name: 'backup',
            component: BackUpView
          }
        ],
      },
      {
        path: '/tutorials',
        name: 'Tutorials',
        component: Tutorials,
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings,
      },
    ],
  },
  
   { 
    path: '/:pathMatch(.*)*',  
    name:'NotFound',
    component: NotFound,    
}, 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  var isAuth = ''
  let name = "authenticated=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      isAuth = c.substring(name.length, c.length);
    }
  }

  switch (to.fullPath) {
    case '/dashboard':
      store.commit('setCurrentMenuTab', ['0'])
      break
    case '/customers':
      store.commit('setCurrentMenuTab', ['1'])
      break
    case '/prices':
      store.commit('setCurrentMenuTab', ['2'])
      break
    case '/invoices':
      store.commit('setCurrentMenuTab', ['3'])
      break
    case '/tutorials':
      store.commit('setCurrentMenuTab', ['4'])
      break
    default:
  }

  if (isAuth === 'true') {
    if (to.path === '/') next('/dashboard')
    else next();
  } else {
    if (to.path === '/') next()
    else next('/');
  }
})

export default router

<template>
  <div>
    <div class="offer-info" v-if="$store.getters.getImportedConfigName">
      <div class="offer-info-title">{{ 'Angebot in Bearbeitung: ' }}</div>
      <div>Angebot: {{ $store.getters.getImportedConfigName }}</div>
      <div v-if="$store.getters.getImportedConfigNumber">Angebotsnummer: {{
          $store.getters.getImportedConfigNumber
        }}
      </div>
      <div v-else>Angebotsnummer: -</div>
    </div>
    <div :key="updateIndex" class="kdz-sidebar-head px-2 py-4">
      <div>Kosten&shy;übersicht</div>
    </div>
    <div class="kdz-cost-list kdz-cost-list-vk">
      <template v-for="(type, index) in serverTypes">
        <div
            v-if="checkServerType(type) || (type=='iaas' && ($store.getters.firewall || $store.getters.ip > 0)) || (type=='wpCloud' && $store.getters.firewall2)"
            class="serverTypeHeader">{{ serverTypeNames[serverTypes[index]] }}
        </div>
        <template v-if="type== 'iaas'">
          <!-- iaas firewall active and ips selected -->
          <template v-if="$store.getters.firewall && $store.getters.ip > 0">
            <div
                v-if="bill[0].bill[0].type !== 'network'"
                :class="[ 'kdz-last-element', 'kdz-cost-border']"
            >
              <a-popover placement="left" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')"
                         v-if="windowWidth>790">
                <span class="hoverLabel">{{ bill[0].bill[0].label }}</span>
              </a-popover>
              <a-popover placement="top" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')"
                         v-if="windowWidth<=790">
                <span class="hoverLabel">{{ bill[0].bill[0].label }}</span>
              </a-popover>

              <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5"
                    class="text-right" align-self="center">
                  <i>{{
                      ((typeof bill[0].bill[0].originalVkPrice === 'string' || bill[0].bill[0].originalVkPrice instanceof String) ? bill[0].bill[0].originalVkPrice : bill[0].bill[0].originalVkPrice.toFixed(2)).toString().replace(".", ",")
                    }}</i> &euro;&nbsp;
                </span>
              <span v-if="bill[0].bill[0].resourceId === undefined" style="width: 30%; display: flex;"
                    align-self="center">
                  <i>{{ bill[0].bill[0].vkSinglePrice.toFixed(2).toString().replace('.', ',') }}</i> &euro;&nbsp;
                </span>
              <div class="cost-input"  v-else>
              <a-input-number   :min="0" decimalSeparator="," :controls="false"
                              v-model:value="bill[0].bill[0].vkSinglePrice"
                              @change="handleEnter($event, bill, bill[0].bill[0].resourceId)"
                              placeholder="Kein Preis verfügbar"/>
              </div>
            </div>
            <div
                v-if="bill[1].bill[0].type !== 'network'"
                :class="[ 'kdz-last-element', 'kdz-cost-border']"
            >
              <a-popover placement="left" :title="(bill[1].bill[0].UsageForm.replace('(','') + 'Netto')"
                         v-if="windowWidth>790">
                <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <a-popover placement="top" :title="(bill[1].bill[0].UsageForm.replace('(','') + 'Netto')"
                         v-if="windowWidth<=790">
                <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5"
                    class="text-right" align-self="center">
                  <i>{{
                      (typeof bill[1].bill[0].originalVkPrice === 'string' || bill[1].bill[0].originalVkPrice instanceof String) ? bill[1].bill[0].originalVkPrice : ($store.getters.firewall2 ? (bill[1].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.', ',') : bill[1].bill[0].originalVkPrice.toFixed(2).toString().replace('.', ','))
                    }}</i> &euro;&nbsp;
                </span>
              <span v-if="bill[1].bill[0].resourceId === undefined" style="width: 30%; display: flex;"
                    align-self="center">
                  <i>{{
                      $store.getters.firewall2 ? (bill[1].bill[0].vkSinglePrice / 2).toFixed(3).toString().replace('.', ',') : bill[1].bill[0].vkSinglePrice.toFixed(2).toString().replace('.', ',')
                    }}</i> &euro;&nbsp;
                </span>
              <div class="cost-input"  v-else>
              <a-input-number  :min="0" decimalSeparator="," :controls="false"
                              v-model:value="bill[1].bill[0].vkSinglePrice"
                              @change="handleEnter($event, bill, bill[1].bill[0].resourceId)"
                              placeholder="Kein Preis verfügbar"/>
              </div>
            </div>
          </template>
          <!-- iaas either firewall or ips are selected -->
          <template v-else-if="$store.getters.ip > 0 || $store.getters.firewall">
            <div
                v-if="bill[0].bill[0].type !== 'network'"
                :class="[ 'kdz-last-element', 'kdz-cost-border']"
            >
              <a-popover placement="left" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')"
                         v-if="windowWidth>790">
                <span class="hoverLabel">{{
                    $store.getters.ip > 0 ? bill[0].bill[0].label : (bill[0].bill[0].label.replace('x2', 'x1'))
                  }}</span>
              </a-popover>
              <a-popover placement="top" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')"
                         v-if="windowWidth<=790">
                <span class="hoverLabel">{{
                    $store.getters.ip > 0 ? bill[0].bill[0].label : (bill[0].bill[0].label.replace('x2', 'x1'))
                  }}</span>
              </a-popover>
              <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5"
                    class="text-right" align-self="center">
                  <i>{{
                      (typeof bill[0].bill[0].originalVkPrice === 'string' || bill[0].bill[0].originalVkPrice instanceof String) ? bill[0].bill[0].originalVkPrice : ($store.getters.firewall2 ? (bill[0].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.', ',') : bill[0].bill[0].originalVkPrice.toFixed(2).toString().replace('.', ','))
                    }}</i> &euro;&nbsp;
                </span>
              <span v-if="bill[0].bill[0].resourceId === undefined" style="width: 30%; display: flex;"
                    align-self="center">
                  <i>{{
                      $store.getters.ip > 0 ? bill[0].bill[0].originalVkPrice.toFixed(2).toString().replace('.', ',') : ($store.getters.firewall2 ? (bill[0].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.', ',') : bill[0].bill[0].originalVkPrice.toFixed(2).toString().replace('.', ','))
                    }}</i> &euro;&nbsp;
                </span>
              <div class="cost-input"  v-else>
              <a-input-number  :min="0" decimalSeparator="," :controls="false"
                              v-model:value="bill[0].bill[0].vkSinglePrice"
                              @change="handleEnter($event, bill, bill[0].bill[0].resourceId)"
                              placeholder="Kein Preis verfügbar"/>
              </div>
            </div>
          </template>
        </template>
        <!-- special case regarding firewall wpCLoud -->
        <template v-if="type== 'wpCloud'">
          <!-- wpcloud firewall active and ips selected -->
          <template v-if="$store.getters.firewall2 && $store.getters.ip > 0">
            <div
                v-if="bill[1].bill[0].type !== 'network'"
                :class="[ 'kdz-last-element', 'kdz-cost-border']"
            >
              <a-popover placement="left" :title="(bill[1].bill[0].UsageForm.replace('(','') + 'Netto')"
                         v-if="windowWidth>790">
                <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <a-popover placement="top" :title="(bill[1].bill[0].UsageForm.replace('(','') + 'Netto')"
                         v-if="windowWidth<=790">
                <span class="hoverLabel">{{ bill[1].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5"
                    class="text-right" align-self="center">
                  <i>{{
                      (typeof bill[1].bill[0].originalVkPrice === 'string' || bill[1].bill[0].originalVkPrice instanceof String) ? bill[1].bill[0].originalVkPrice : ($store.getters.firewall ? (bill[1].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.', ',') : bill[1].bill[0].originalVkPrice.toFixed(2).toString().replace('.', ','))
                    }}</i> &euro;&nbsp;
                </span>
              <span v-if="bill[1].bill[0].resourceId === undefined" style="width: 30%; display: flex;"
                    align-self="center">
                  <i>{{
                      $store.getters.firewall ? (bill[1].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.', ',') : bill[1].bill[0].originalVkPrice.toFixed(2).toString().replace('.', ',')
                    }}</i> &euro;&nbsp;
                </span>
              <div class="cost-input"  v-else>
              <a-input-number :min="0" decimalSeparator="," :controls="false"
                              v-model:value="bill[1].bill[0].vkSinglePrice"
                              @change="handleEnter($event, bill, bill[1].bill[0].resourceId)"
                              placeholder="Kein Preis verfügbar"/>
              </div>
            </div>
          </template>
          <!-- wpCloud firewall active and ips unselected -->
          <template v-else-if="$store.getters.firewall2">
            <div
                v-if="bill[0].bill[0].type !== 'network'"
                :class="['kdz-last-element','kdz-cost-border']"
            >
              <a-popover placement="left" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')"
                         v-if="windowWidth>790">
                <span class="hoverLabel">{{ bill[0].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <a-popover placement="top" :title="(bill[0].bill[0].UsageForm.replace('(','') + 'Netto')"
                         v-if="windowWidth<=790">
                <span class="hoverLabel">{{ bill[0].bill[0].label.replace('x2', 'x1') }}</span>
              </a-popover>
              <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5"
                    class="text-right" align-self="center">
                  <i>{{
                      (typeof bill[0].bill[0].originalVkPrice === 'string' || bill[0].bill[0].originalVkPrice instanceof String) ? bill[0].bill[0].originalVkPrice : ($store.getters.firewall ? (bill[0].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.', ',') : bill[0].bill[0].originalVkPrice.toFixed(2).toString().replace('.', ','))
                    }}</i> &euro;&nbsp;
                </span>
              <span v-if="bill[0].bill[0].resourceId === undefined" style="width: 30%; display: flex;"
                    align-self="center">
                  <i>{{
                      $store.getters.firewall ? (bill[0].bill[0].originalVkPrice / 2).toFixed(2).toString().replace('.', ',') : bill[0].bill[0].originalVkPrice.toFixed(2).toString().replace('.', ',')
                    }}</i> &euro;&nbsp;
                </span>
              <div class="cost-input"  v-else>
              <a-input-number  :min="0" decimalSeparator="," :controls="false"
                              v-model:value="bill[0].bill[0].vkSinglePrice"
                              @change="handleEnter($event, bill, bill[0].bill[0].resourceId)"
                              placeholder="Kein Preis verfügbar"/>
              </div>
            </div>
          </template>
        </template>
        <template v-for="(pos, index) in bill" :key="index + pos.serverName">
          <!-- sorter for servertypes-->
          <template v-if="pos.type === type  || (pos.type=='containerIaaS' && type == 'iaas')">
            <!-- serverHeader unfolded -->
            <template v-if="showCosts.includes(pos.id) || selectedServer.id == pos.id">
              <div class="serverHeader" style="display: flex; cursor: pointer;"
                   @click="showCosts.splice(showCosts.indexOf(pos.id), 1)">
                <strong
                    v-html="pos.serverName.includes('()') ? pos.serverName.replace(' <small>()</small>','') : pos.serverName"></strong>
                <div style="text-align: right; flex-grow: 4;">
                  <UpOutlined/>
                </div>
              </div>
            </template>
            <!-- ServerHeader folded -->
            <template v-else>
              <div :class="['serverHeader']" style="display: flex; cursor: pointer;"
                   @click="showCosts.push(pos.id)">
                <strong
                    v-html="pos.serverName.includes('()') ? pos.serverName.replace(' <small>()</small>','') : pos.serverName"></strong>
                <div style="text-align: right; flex-grow: 4;">
                  <DownOutlined/>
                </div>
              </div>
            </template>
            <!-- invitems unfolded-->
            <template v-for="(invItem, ind) in pos.bill">
              <template v-if="showCosts.includes(pos.id) || selectedServer.id == pos.id">
                <div
                    :key="ind + '_' + index + pos.serverName"
                    v-if="invItem.type !== 'network'"
                    :class="[{ 'kdz-last-element': ind === pos.bill.length - 1 }, 'kdz-cost-border']"
                >
                  <a-popover
                      placement="left"
                      :title="invItem.label.includes('Container vCPU') ? ('Container vCPU (3,2GHz), ' + invItem.UsageForm.replace('(','') + 'Netto') : (invItem.UsageForm.replace('(','') + 'Netto')"
                      v-if="windowWidth>790">
                    <span class="hoverLabel">{{ invItem.label }}</span>
                  </a-popover>
                  <a-popover
                      placement="top"
                      :title="invItem.label.includes('Container vCPU') ? ('Container vCPU (3,2GHz), ' + invItem.UsageForm.replace('(','') + 'Netto') : (invItem.UsageForm.replace('(','') + 'Netto')"
                      v-if="windowWidth<=790">
                    <span class="hoverLabel">{{ invItem.label }}</span>
                  </a-popover>

                  <span class="text-right">
                      <i>{{
                          ((typeof invItem.originalVkPrice === 'string' || invItem.originalVkPrice instanceof String) ? invItem.originalVkPrice : invItem.originalVkPrice.toFixed(2)).toString().replace(".", ",")
                        }}</i> &euro;&nbsp;
                    </span>
                  <span class="text-right" v-if="invItem.resourceId === undefined">
                      <i>{{ invItem.vkSinglePrice.toFixed(2).toString().replace('.', ',') }}</i> &euro;&nbsp;
                    </span>
                  <div class="cost-input" v-else>
                    <a-input-number  :min="0" decimalSeparator="," :controls="false"
                                    v-model:value="invItem.vkSinglePrice"
                                    @change="handleEnter($event, bill, invItem.resourceId)"
                                    placeholder="Kein Preis verfügbar"/>
                  </div>

                </div>
              </template>
            </template>
            <!-- invtitem -->
          </template>
          <!-- servetype -->
        </template>
      </template>
    </div>
    <div :key="updateSum" class="kdz-cost-sum text-right">
      <span> Gesamtkosten Netto VK/ Monat<br/></span>
      <strong class="cost-summary-right"><span>Summe:</span> <span class="text-right">{{ vkPriceSummary.toString().replace(".", ",") }} €</span></strong>
      <span style="margin-top: 10px"> Gesamtkosten Netto EK/ Monat<br/></span>
      <strong class="cost-summary-right"><span>Summe:</span> <span class="text-right">{{ summary[0].toString().replace(".", ",") }} €</span></strong>
      <strong style="margin-top: 10px" class="cost-summary-right"><span>Netto Ertrag:</span> <span class="text-right">{{ getDifferenz(vkPriceSummary, summary[0]).toString() }} €</span>
        </strong>
    </div>
  </div>
</template>

<script setup>

//IMPORTS
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import { ref, computed } from 'vue'
import store from '@/store'

//VARIABLES
const serverTypes = ["container", "cloudServer", "iaas", "wpCloud", "cybercloudendpoint"]
const serverTypeNames = {
  container: "Container Deployments",
  cloudServer: "Cloud-Server",
  iaas: "Infrastructure as a Service",
  wpCloud: "Online Office",
  cybercloudendpoint: "Cyber Cloud Endpoint"
}

const showCosts     = ref([])
const updateIndex   = ref(3)
const updateSum     = ref(0)
const total         = ref(0)
const fromInput     = ref(false)
const windowWidth   = ref(window.innerHeight) 

//COMPUTED PROPERTIES
const bill = computed(() => store.getters.bill)
const selectedServer = computed(() => store.getters.selectedServer)
const serverList = computed(() => store.getters.serverList);
const summary = computed(() => {
  let sum = 0.0
  let originalSum = 0.0

  const billItems = store.getters.bill

  billItems.forEach((el) => {
    for (let pos of el.bill) {
      sum += Number(pos.price);
      if (pos.originalPrice !== undefined) {
        originalSum += Number(pos.originalPrice)
      }
    }
  })

  return [sum.toFixed(2), originalSum.toFixed(2)]
})

const vkPriceSummary = computed(() => {
  if(!fromInput.value){
    let sum = 0.0
    const billItems = store.getters.bill
    billItems.forEach((el) => {
      for (let pos of el.bill) {
        if(pos.originalVkPrice != undefined){
          sum += Number(pos.originalVkPrice)
        }
      }
    });
    return sum.toFixed(2)
  } else {
    return total.value.toFixed(2)
  }   
})

//FUNCTIONS
function checkServerType (type) {
  for (let i = 0; i < bill.value.length; i++) {
    if (
      bill.value[i].type !== undefined &&
      (bill.value[i].type === type ||
        (bill.value[i].type === "containerIaaS" && type === "iaas"))
    ) {
      return true;
    }
  }
  return false;
}

function handleEnter(event, bill, resourceId) {
  total.value = 0
  fromInput.value = true
  var firstIp = false
  var specialCase = false
  for (var server of bill) {
    specialCase = false
    var resource = server.bill.filter(x => x.resourceId === resourceId)[0]
    if (resource !== undefined) {
      resource.vkSinglePrice = event
      var tempvkSinglePrice = resource.vkSinglePrice
      // logic to make sure that allways only one IP per OFFer is free
      if (resourceId == 3) {
        if(bill.length == 1) {
          if(resource.count == 1) {
            resource.originalVkPrice = 0
            specialCase = true
          }
          else{
          resource.originalVkPrice = tempvkSinglePrice * ((parseInt(resource.count)) - 1)
          specialCase = true
          }
        } else {
          if(resource.count == "1" && !firstIp) {
              firstIp = true
              specialCase = true
              resource.originalVkPrice = 0
          }
        }
      }

     //Calculate changes to the sales price for the CyberCloudRemoteBackup
     if(server.type == 'cloudServer' || server.type == 'iaas') {
          if(resourceId == 1002) {
              var storageSum = 0
              var storageList = server.bill.filter(x => x.resourceId === 43 || x.resourceId == 44)
              if(storageList != undefined){
                for(const element of storageList){
                  storageSum += Number(element.count)
                }
              resource.originalVkPrice = tempvkSinglePrice * storageSum
              specialCase = true
          }
        }
      }
          
      //Multiply CPU or RAM in containers with replicas
      if(resourceId == 1004 || resourceId == 1005) {
        var filteredList = serverList.value.filter(x=>x.id == server.id)
        var replicas = filteredList.length > 0 ? filteredList[0].configuration.Replicas : null
        if(replicas != null){
          resource.originalVkPrice = tempvkSinglePrice * parseInt(resource.count) * replicas
          specialCase = true
        }
      }

      // MSSQL set total to 0 if no Cpus are configured
      if (resourceId == 5 && resource.count == 0){
        tempvkSinglePrice = 0
      } 
      if(specialCase == false){
        resource.originalVkPrice = tempvkSinglePrice * parseInt(resource.count)
      }     
      store.commit('setCustomVkPrices', {id:resourceId, customPrice: resource.vkSinglePrice})

      for(let i = 0; i<server.bill.length; i++) {
        if(server.bill[i].originalVkPrice != undefined){
              total.value += Number(server.bill[i].originalVkPrice)
        }  
      }

      updateSum.value += 1
      updateIndex.value += 1
      fromInput.value = false
      store.commit("setWorkingOnOffer", true)
    }
  }
}

function getDifferenz(original, discount) {
  var diff = original - discount
  return diff.toFixed(2)
}

</script>

<style scoped lang="scss">
.text-right {
  text-align: right;
}

.kdz-cost-list-vk {
  .kdz-cost-border {
    display: grid;
    grid-template-columns: 1fr 65px 70px;
    gap: 4px;
    align-items: center;
    .hoverLabel{
      width: 100%;
    }
    .text-right {

    }

    .cost-input {
      padding-top: 4px;
      padding-bottom: 4px;
      .ant-input-number {
        width: auto;
        font-size: 14px;

      }
    }
  }
}
</style>